import React, {useState, useEffect, useContext} from 'react';
import {styled} from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Links from '@material-ui/core/Link';
import {Helmet} from "react-helmet";
import {titles, drawerWidth, drawer} from "../services/auth"; // , userCheck
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import PublicIcon from '@material-ui/icons/Public';
import axios from "axios";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Skeleton from '@material-ui/lab/Skeleton';
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";
import {DropzoneArea} from "material-ui-dropzone";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {green} from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import {User} from "./User";

function SimpleDialog(props) {

    const {onClose, selectedValue, open, signaturesValue, avatarValue} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value, key) => {
        onClose(value, key);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Выбор подписи в отзыве</DialogTitle>
            <List>
                {signaturesValue.map((signature) => (
                    <ListItem button onClick={() => handleListItemClick(signature.name, signature.id)}
                              key={signature.id}>
                        <ListItemAvatar>
                            {
                                avatarValue !== "" && signature.name !== "Anonymous" ?
                                    <Avatar alt={signature.name}
                                            src={avatarValue}/> :
                                    <Avatar
                                        alt={signature.name}><PersonOutlineOutlinedIcon/></Avatar>
                            }
                        </ListItemAvatar>
                        <ListItemText primary={signature.name}/>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
    signaturesValue: PropTypes.array.isRequired,
    avatarValue: PropTypes.string.isRequired,
};

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledPaperLeftMenu = styled(Paper)(({theme}) => ({
    padding: theme.spacing(0),
    margin: 'auto',
}));
const StyledDrawerNav = styled('nav')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    marginRight: "20px",
}));
const StyledIconButton = styled(IconButton)(({theme}) => ({
    [theme.breakpoints.up(960)]: {
        display: 'none',
    },
}));
const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
}));
const StyledRatingDiv = styled('div')(({theme}) => ({
    padding: 0,
    margin: 0,
}));
const StyledButtonBase = styled(ButtonBase)(({theme}) => ({
    width: 256,
}));

let elementId = 0;
let reviewId = 0;
let q = "";

// TODO получать значения через API json
const labels = {
    1: 'Ужасно',
    2: 'Плохо',
    3: 'Средне',
    4: 'Хорошо',
    5: 'Отлично',
};

if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search);
    elementId = roughScale(queryParams.get('elementId') === null ? 0 : queryParams.get('elementId'));
    reviewId = roughScale(queryParams.get('reviewId') === null ? 0 : queryParams.get('reviewId'));
    q = queryParams.get('q') === null ? "" : queryParams.get('q');
}

function urlReplace(id, field) {
    // if (field === "elementId") elementId = id === 0 && field === "elementId" ? elementId : id;
    // if (field === "reviewId") reviewId = id === 0 ? reviewId : id;
    if (field === "elementId") elementId = id;
    if (field === "reviewId") reviewId = id;
    window.history.pushState(
        {elementId: elementId, reviewId: reviewId, q: q},
        "",
        "review?elementId=" + elementId + "&reviewId=" + reviewId + (q !== "" ? "&q=" + q : "")
    )
}

function roughScale(id) {
    const parsed = parseInt(id);
    if (isNaN(parsed)) {
        return 0;
    }
    return parsed;
}

const Review = (props) => {

    const {window} = props;
    const pathName = props.path
    const [valueTitle, setValueTitle] = useState()
    const [dateSaveElement, setDateSaveElement] = useState(false);
    const [dateSaveReview, setDateSaveReview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPublicReview, setLoadingPublicReview] = useState(false);
    const [loadingElement, setLoadingElement] = useState(false);
    const [reviewIdUpdate, setReviewIdUpdate] = useState(false);

    function setTitle() {
        titles.map((title, t) => (
            typeof pathName !== 'undefined' && pathName === title.link && valueTitle !== title.name ? setValueTitle(title.name) : ""
        ))
    }

    setTitle()
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [user] = useContext(User);
    const [signatures, setSignatures] = useState([]);
    const [field, setFields] = useState({
        reviewDignity: "",
        reviewDisadvantage: "",
        reviewComment: "",
        reviewRating: 0,
        reviewAttach: [],
        reviewStatus: 6,
        reviewSignature: 0,
    })

    useEffect(() => {
        if (user.notificationsData !== undefined) {
            let name = (user.userData.name !== undefined ? " " + user.userData.name : "") + (user.userData.secondName !== undefined ? " " + user.userData.secondName : "") + (user.userData.lastName !== undefined ? " " + user.userData.lastName : "");
            name = name.trim();
            setSignatures([
                {
                    id: 71,
                    name: user.userData.nickname
                },
                {
                    id: 73,
                    name: "Anonymous"
                }
            ]);
            if (name !== "") {
                setSignatures([
                    {
                        id: 71,
                        name: user.userData.nickname
                    },
                    {
                        id: 72,
                        name: name
                    },
                    {
                        id: 73,
                        name: "Anonymous"
                    }
                ]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (signatures.length > 0 && signatures[0].id !== undefined && signatures[0].name !== undefined && parseInt(field.reviewSignature) > 0) {
            setSelectedValueSign(signatures.find(el => parseInt(el.id) === parseInt(field.reviewSignature)).name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    useEffect(() => {
        if (signatures.length > 0 && signatures[0].id !== undefined && signatures[0].name !== undefined && parseInt(field.reviewSignature) > 0) {
            setSelectedValueSign(signatures.find(el => parseInt(el.id) === parseInt(field.reviewSignature)).name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatures]);

    // Получекние тегов
    const [tags, setTags] = useState([]);
    useEffect(() => {

        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/tags-get.php",
            responseType: 'json',
        })
            .then(res => {
                setTags(res.data);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }, []);

    // element
    const [editActionElement, setEditActionElement] = useState(false)
    const [reloadElement, setReloadElement] = useState(false)

    function editElement(e) {
        setEditActionElement(e)
        if (e !== false) {
            setReloadElement(reloadElement + 1)
        }
    }

    const options = tags.map((option) => {
        const firstLetter = option.id !== undefined && option.id[0] !== undefined ? option.id[0].toUpperCase() : "";
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });
    const [elementInit, setElementInit] = useState(false);
    const [tagsElement, setTagsElement] = useState([]);
    const [elementData, setElementData] = useState({
        edit: false,
        id: 0,
        rating: 0,
        url: "",
        name: q,
        reviews_count_number: "",
        reviews_count_text: "",
        text_short: "",
        tags: [],
        photo: [],
        elementStatus: 4,
        elementFiles: [],
        elementPhoto: [],
        elementPublic: false
    });
    useEffect(() => {
        if (elementId !== 0) {
            let formData = new FormData();
            formData.append(
                "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
            );
            formData.append(
                "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
            );
            formData.append(
                "elementId", elementId,
            );
            if (reloadElement !== false) {
                formData.append(
                    "action", "edit",
                );
            }
            axios({
                method: "POST",
                data: Object.assign(formData),
                url: "https://api.ocenivay.com/api/element-get.php",
                responseType: 'json',
            })
                .then(res => {
                    if (roughScale(res.data.elementId) > 0) {
                        let arFilesGet = res.data.elementData.elementFiles;
                        let arFilesSet = [];
                        if (arFilesGet.length > 0) {
                            arFilesGet.forEach(function (file) {
                                const byteCharacters = atob(file.data);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                const blob = new Blob([byteArray], {type: file.type});
                                let newFile = new File([blob], file.name, {type: file.type});
                                arFilesSet.push(newFile);
                            });
                        } else {
                            arFilesSet = [];
                        }
                        setElementData({
                            edit: res.data.edit,
                            rating: res.data.elementData.rating,
                            url: res.data.elementData.url,
                            name: res.data.elementData.name,
                            text: res.data.elementData.text,
                            reviews_count_number: res.data.elementData.reviews_count_number,
                            reviews_count_text: res.data.elementData.reviews_count_text,
                            text_short: res.data.elementData.text_short,
                            tags: res.data.elementData.tags,
                            photo: res.data.elementData.photo,
                            elementFiles: arFilesSet,
                            elementPhoto: res.data.elementData.elementPhoto,
                            elementStatus: res.data.elementStatus,
                            elementPublic: res.data.public,
                        });
                        setTagsElement(res.data.elementData.tags);
                        setDateSaveElement(res.data.dateSave);
                        if (res.data.elementStatus === 4) {
                            setEditActionElement(true)
                            setAutocompleteTagsInit(true);
                            setDropzoneAreaElementFilesInit(true);
                            setDropzoneAreaElementPhotoInit(true);
                        } else {
                            setElementInit(true)
                        }
                        if (reloadElement !== false) {
                            setAutocompleteTagsInit(true);
                            setDropzoneAreaElementFilesInit(true);
                            setDropzoneAreaElementPhotoInit(true);
                        }
                    } else {
                        urlReplace(roughScale(res.data.elementId), "elementId")
                        setEditActionElement(true)
                        setAutocompleteTagsInit(true);
                        setDropzoneAreaElementFilesInit(true);
                        setDropzoneAreaElementPhotoInit(true);
                    }

                })
                .catch(function (error) {
                    console.log('Error', error.message);
                });
        } else {
            setEditActionElement(true)
            urlReplace(0, "elementId")
            setAutocompleteTagsInit(true);
            setDropzoneAreaElementFilesInit(true);
            setDropzoneAreaElementPhotoInit(true);
        }

    }, [reloadElement]);

    const [reviewInit, setReviewInit] = useState(true);
    useEffect(() => {
        if (reviewId !== 0) {
            let values;
            let formData = new FormData();
            formData.append(
                "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
            );
            formData.append(
                "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
            );
            formData.append(
                "elementId", elementId,
            );
            formData.append(
                "reviewId", roughScale(reviewId),
            );
            values = Object.assign(formData);
            axios({
                method: "POST",
                data: values,
                url: "https://api.ocenivay.com/api/review-get.php",
                responseType: 'json',
            })
                .then(res => {
                    if (roughScale(res.data.reviewId) > 0) {
                        setFields({
                            reviewDignity: res.data.reviewData.UF_PLUS,
                            reviewDisadvantage: res.data.reviewData.UF_MINUS,
                            reviewComment: res.data.reviewData.UF_COMMENT,
                            reviewAttach: res.data.reviewData.UF_PHOTO,
                            reviewRating: res.data.reviewData.UF_VOTE_TOTAL,
                            reviewStatus: res.data.reviewData.UF_STATUS,
                            reviewSignature: res.data.reviewData.UF_SIGNATURE,
                        });
                        setReviewInit(res.data.edit)
                        setDateSaveReview(res.data.dateSave);
                        setRatingValue(res.data.reviewData.UF_VOTE_TOTAL);
                    } else {
                        urlReplace(roughScale(res.data.reviewId), "reviewId")
                    }
                    setDropzoneAreaInit(true);
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                });
        } else {
            urlReplace(0, "reviewId")
            setDropzoneAreaInit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // roughScale(reviewIdUpdate.reviewId)

    function publicElement() {
        saveElement(true)
    }

    function saveElement(publicActionElement) {
        let values;
        let formData = new FormData();
        for (const [key, value] of Object.entries(elementData)) {
            if (key !== "elementPhoto" && key !== "elementFiles" && key !== "tags") {
                formData.append(key, value)
            } else {
                if (key === "tags") {
                    for (let index = 0; index < tagsElement.length; index++) {
                        formData.append("tags[]", tagsElement[index].id)
                    }
                }
                if (key === "elementPhoto") {
                    for (let index = 0; index < elementData.elementPhoto.length; index++) {
                        formData.append("elementPhoto[]", elementData.elementPhoto[index])
                    }
                }
                if (key === "elementFiles") {
                    for (let index = 0; index < elementData.elementFiles.length; index++) {
                        formData.append("elementFiles[]", elementData.elementFiles[index])
                    }
                }
            }
        }
        if (publicActionElement) {
            formData.append(
                "publicActionElement", publicActionElement,
            );
        }
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        formData.append(
            "elementId", elementId,
        );
        values = Object.assign(formData);
        if (!loading) {
            setLoadingElement(true);
        }
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/element-save.php",
            responseType: 'json',
        })
            .then(res => {
                setSnackbarMessage(res.data.severity, res.data.message)
                setLoadingElement(false);
                urlReplace(roughScale(res.data.elementId), "elementId")
                setElementData({
                    ...elementData, edit: res.data.edit
                });
                setElementData({
                    ...elementData, elementPublic: res.data.public
                });
                setDateSaveElement(res.data.dateSave)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    // function cancelElement() {
    //     alert("cancelElement");
    // }
    //
    // function deleteElement() {
    //     alert("deleteElement");
    // }

    function publicReview() {
        saveReview(true)
    }

    // Сохранение данных, введенных клиентом
    function saveReview(publicActionReview) {
        let values;
        let formData = new FormData();
        for (const [key, value] of Object.entries(field)) {
            if (key !== "reviewAttach") {
                formData.append(key, value)
            } else {
                if (key === "reviewAttach") {
                    for (let index = 0; index < field.reviewAttach.length; index++) {
                        formData.append("reviewAttach[]", field.reviewAttach[index])
                    }
                }
            }
        }
        if (publicActionReview) {
            formData.append(
                "publicActionReview", publicActionReview,
            );
            if (!loading) {
                // setSuccess(false);
                setLoadingPublicReview(true);
            }
        } else {
            if (!loading) {
                // setSuccess(false);
                setLoading(true);
            }
        }
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        formData.append(
            "elementId", elementId,
        );
        formData.append(
            "reviewId", roughScale(reviewId),
        );
        values = Object.assign(formData);

        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/review-save.php",
            responseType: 'json',
        })
            .then(res => {
                setSnackbarMessage(res.data.severity, res.data.message)
                setReviewIdUpdate({
                    elementId: res.data.elementId,
                    reviewId: res.data.reviewId
                });
                setFields({
                    ...field, reviewStatus: res.data.reviewStatus
                });
                if (parseInt(res.data.reviewStatus) === 7 || parseInt(res.data.reviewStatus) === 9 || parseInt(res.data.reviewStatus) === 43) {
                    setEditActionElement(false)
                }

                // if (res.data.edit === false) {
                //     setEditActionElement(false)
                //     setElementInit(true)
                // }

                if (parseInt(res.data.reviewStatus) === 7 || parseInt(res.data.reviewStatus) === 9) {
                    setReloadElement(true);
                }

                setReviewInit(res.data.edit)
                setLoading(false);
                setLoadingPublicReview(false);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    // useEffect(() => {
    //     userCheck().then(res => {
    //         console.log(res.userData);
    //     })
    //         .catch(function (error) {
    //             console.log('Error', error.message);
    //         });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        urlReplace(roughScale(reviewIdUpdate.elementId) === 0 ? elementId : roughScale(reviewIdUpdate.elementId), "elementId")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roughScale(reviewIdUpdate.elementId)]);
    useEffect(() => {
        urlReplace(roughScale(reviewIdUpdate.reviewId) === 0 ? reviewId : roughScale(reviewIdUpdate.reviewId), "reviewId")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roughScale(reviewIdUpdate.reviewId)]);

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    const [valueRating, setRatingValue] = useState(0);
    const [hoverRating, setRatingHover] = useState(-1);

    const [dropzoneAreaInit, setDropzoneAreaInit] = useState(false);
    const [dropzoneAreaElementPhotoInit, setDropzoneAreaElementPhotoInit] = useState(false);
    const [dropzoneAreaElementFilesInit, setDropzoneAreaElementFilesInit] = useState(false);
    const [autocompleteTagsInit, setAutocompleteTagsInit] = useState(false);

    function handleTagsElementChange(event) {
        setTagsElement(event);
    }

    function handleElementChange(event) {

        let elementPhoto = 'elementPhoto';
        if (event.elementPhoto) {
            setElementData({
                ...elementData, [elementPhoto]: event.elementPhoto
            });
        }

        let elementFiles = 'elementFiles';
        if (event.elementFiles) {
            setElementData({
                ...elementData, [elementFiles]: event.elementFiles
            });
        }

        const target = event !== undefined ? event.target : undefined;

        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            setElementData({
                ...elementData, [name]: value
            });
        }
    }

    function handleFieldChange(event) {

        let reviewAttach = 'reviewAttach';
        if (event.reviewAttach) {
            setFields({
                ...field, [reviewAttach]: event.reviewAttach
            });
        }

        const target = event !== undefined ? event.target : undefined;

        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            setFields({
                ...field, [name]: value
            });
            if (name === "reviewRating") {
                setRatingValue(value);
            }
        }
    }


    const [openSign, setOpenSign] = useState(false);
    const [selectedValueSign, setSelectedValueSign] = useState("");

    const handleClickOpenSign = () => {
        setOpenSign(true);
    };

    const handleCloseSign = (value, key) => {
        setOpenSign(false);
        setSelectedValueSign(value);
        setFields({
            ...field, reviewSignature: key
        });
    };

    return (

        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{valueTitle}</title>
                <meta name="description" content={valueTitle}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Grid container>

                <Grid item xs={false} md={3}>
                    <StyledDrawerNav aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <StyledDrawer
                                container={container}
                                variant="temporary"
                                anchor="left"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </StyledDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <StyledPaperLeftMenu>{drawer}</StyledPaperLeftMenu>
                        </Hidden>
                    </StyledDrawerNav>
                </Grid>
                <Grid item xs={12} md={9}>
                    <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom component="h1" variant="h5"
                                            color="textPrimary">
                                    <StyledIconButton
                                        color="inherit"
                                        aria-label="show more"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            textTransform: 'none',
                                            margin: '-7px 0px 0px -10px'
                                        }}
                                    >
                                        <MenuIcon/>
                                    </StyledIconButton>

                                    {valueTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>Редактирование и добавление объектов и
                                    отзывов. Нажмите на кнопку <b>"Сохранить"</b>, чтобы вернуться в любой момент и
                                    продолжить заполнение полей.</Typography>
                            </Grid>
                        </Grid>
                    </StyledPaper>


                    {
                        (!editActionElement && elementInit ?
                            <StyledPaper
                                style={{
                                    margin: '20px 0px 20px 0px'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column">
                                            <Grid item xs>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    <Grid>
                                                        <StyledRatingDiv>
                                                            <Rating name="half-rating-read"
                                                                    style={{
                                                                        margin: '0 10px 0 0'
                                                                    }}
                                                                    value={parseFloat(elementData.rating)}
                                                                    precision={0.5} readOnly/>
                                                        </StyledRatingDiv>
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            padding: '3px 0 0 0'
                                                        }}
                                                    >
                                                        <Links href={elementData.url} style={{
                                                            textDecoration: 'underline',
                                                            fontSize: '0.8rem',
                                                        }}
                                                        >Рейтинг на
                                                            основе {elementData.reviews_count_number} {elementData.reviews_count_text}</Links>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs>
                                                <Links href={elementData.url}
                                                       style={{
                                                           color: '#000000',
                                                           textShadow: 'none',
                                                           backgroundImage: 'none',
                                                           fontSize: '1.2rem',
                                                           fontWeight: 400,
                                                       }}
                                                >{elementData.name}</Links>
                                                <Typography variant="body2" gutterBottom><span
                                                    dangerouslySetInnerHTML={{__html: elementData.text_short}}/>&nbsp;
                                                    <Links href={elementData.url}
                                                           style={{
                                                               textDecoration: 'underline',
                                                           }}
                                                    >Подробнее...</Links>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                {
                                                    (elementData.tags !== undefined ?
                                                            elementData.tags.map((tag, t) => {
                                                                    return (
                                                                        <Box
                                                                            style={{
                                                                                marginTop: 10
                                                                            }}
                                                                            key={t}
                                                                        >
                                                                            <Links href={tag.url}
                                                                                   style={{
                                                                                       color: '#3f51b5',
                                                                                       textShadow: 'none',
                                                                                       backgroundImage: 'none',
                                                                                       fontSize: '0.8rem',
                                                                                       margin: '0px 10px 0px 0px'
                                                                                   }}
                                                                            >#{tag.name}</Links>
                                                                        </Box>
                                                                    )
                                                                }
                                                            )
                                                            :
                                                            ""
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <StyledButtonBase>
                                            <Links href={elementData.url}>
                                                {
                                                    (elementData !== undefined && elementData.photo !== undefined &&
                                                        elementData.photo.element !== undefined
                                                            ?
                                                            <img
                                                                src={elementData.photo.element.preview.src}
                                                                alt={elementData.name}
                                                                width={elementData.photo.element.preview.width}
                                                                height={elementData.photo.element.preview.height}
                                                            />
                                                            :
                                                            <Skeleton animation="wave" variant="rect" width={256}
                                                                      height={182}/>
                                                    )
                                                }

                                            </Links>
                                        </StyledButtonBase>
                                    </Grid>
                                </Grid>
                                {
                                    (elementData.edit ?
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button variant="outlined" size="small"
                                                            style={{
                                                                textShadow: 'none',
                                                                backgroundImage: 'none',
                                                                fontSize: '0.8rem',
                                                                fontWeight: 400,
                                                                textTransform: 'none',
                                                            }}
                                                            startIcon={<EditIcon
                                                                style={{
                                                                    margin: '0px 0px 3px 0px'
                                                                }}
                                                            />}
                                                            onClick={() => Promise.resolve(editElement(true))}
                                                            disabled={loading}
                                                    >Редактировать</Button>
                                                </Grid>
                                                {/*<Grid item xs={4}*/}
                                                {/*      style={{*/}
                                                {/*          textAlign: "center"*/}
                                                {/*      }}*/}
                                                {/*>*/}
                                                {/*    <Button variant="outlined" size="small"*/}
                                                {/*            style={{*/}
                                                {/*                textShadow: 'none',*/}
                                                {/*                backgroundImage: 'none',*/}
                                                {/*                fontSize: '0.8rem',*/}
                                                {/*                fontWeight: 400,*/}
                                                {/*                textTransform: 'none',*/}
                                                {/*            }}*/}
                                                {/*            startIcon={<CancelIcon*/}
                                                {/*                style={{*/}
                                                {/*                    margin: '0px 0px 3px 0px'*/}
                                                {/*                }}*/}
                                                {/*            />}*/}
                                                {/*            onClick={() => Promise.resolve(cancelElement())}*/}
                                                {/*            disabled={loading}*/}
                                                {/*    >Снять с публикации</Button>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}*/}
                                                {/*      style={{*/}
                                                {/*          textAlign: "right"*/}
                                                {/*      }}*/}
                                                {/*>*/}
                                                {/*    <Button variant="outlined" size="small"*/}
                                                {/*            style={{*/}
                                                {/*                textShadow: 'none',*/}
                                                {/*                backgroundImage: 'none',*/}
                                                {/*                fontSize: '0.8rem',*/}
                                                {/*                fontWeight: 400,*/}
                                                {/*                textTransform: 'none',*/}
                                                {/*            }}*/}
                                                {/*            startIcon={<DeleteIcon*/}
                                                {/*                style={{*/}
                                                {/*                    margin: '0px 0px 3px 0px'*/}
                                                {/*                }}*/}
                                                {/*            />}*/}
                                                {/*            onClick={() => Promise.resolve(deleteElement())}*/}
                                                {/*            disabled={loading}*/}
                                                {/*    >Удалить</Button>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                            :
                                            ""
                                    )
                                }
                            </StyledPaper>
                            : "")
                    }

                    {
                        (editActionElement ?
                                <StyledPaper
                                    style={{
                                        margin: '20px 0px 20px 0px'
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom component="p" variant="h6"
                                                        color="textPrimary">Объект</Typography>
                                            <Typography variant="body2" gutterBottom>Для публикации объекта необходимо
                                                заполнить все поля
                                                формы.<br/>Нажмите на кнопку <b>"Сохранить"</b>, чтобы вернуться в любой
                                                момент и продолжить заполнение полей.</Typography>
                                        </Grid>
                                        {
                                            elementData.elementStatus === 4 && dateSaveElement !== false &&
                                            <Grid item xs={12}
                                                  style={{
                                                      margin: '-20px 0px 0px 0px'
                                                  }}
                                            >
                                                <Alert variant="outlined" severity="info">
                                                    Объект успешно сохранен: <b>{dateSaveElement}</b>, но не
                                                    опубликован.
                                                    Заполните все поля по объекту и отзыву, после нажмите "Опубликовать
                                                    отзыв".
                                                </Alert>
                                            </Grid>
                                        }
                                        {
                                            (elementData.elementStatus === 5 || elementData.elementStatus === 8) && dateSaveElement !== false &&
                                            <Grid item xs={12}
                                                  style={{
                                                      margin: '-20px 0px 0px 0px'
                                                  }}
                                            >
                                                <Alert variant="outlined" severity="success">
                                                    Объект успешно опубликован: <b>{dateSaveElement}</b>
                                                </Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                variant="outlined"
                                                label="Название товара, компании или услуги"
                                                name="name"
                                                value={elementData.name}
                                                onChange={handleElementChange}
                                                helperText="Например: Автоматическая кофемашина Siemens EQ.6 plus s100"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                multiline
                                                minRows={2}
                                                maxRows={16}
                                                fullWidth
                                                variant="outlined"
                                                label="Описание, характеристики"
                                                name="text"
                                                value={elementData.text}
                                                onChange={handleElementChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {autocompleteTagsInit ?
                                                <Autocomplete
                                                    name="tags"
                                                    onChange={(event, value) => handleTagsElementChange(value)}
                                                    multiple
                                                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                    groupBy={(option) => option.firstLetter}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                    defaultValue={elementData.tags ? elementData.tags : null}
                                                    renderTags={(tagValue, getTagProps) => {
                                                        return tagValue.map((option, index) => (
                                                            <Chip variant="outlined"
                                                                  color="primary" {...getTagProps({index})}
                                                                  label={option.name}/>
                                                        ));
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            variant="outlined"
                                                            label="Теги или категории"
                                                            placeholder="Можно выбрать несколько тегов"
                                                            value={params}
                                                        />
                                                    )}
                                                /> : ""}
                                        </Grid>
                                        <Grid item xs={12}
                                              style={{
                                                  textAlign: 'center'
                                              }}
                                        >
                                            {dropzoneAreaElementPhotoInit ?
                                                <DropzoneArea
                                                    initialFiles={elementData.elementPhoto}
                                                    acceptedFiles={['image/*']}
                                                    name="elementPhoto"
                                                    onChange={(elementPhoto) => handleElementChange({"elementPhoto": elementPhoto})}
                                                    dropzoneText={"Официальные фотографии товара, компании или услуги. Не более 20 шт."}
                                                    filesLimit={20}
                                                    maxFileSize={52428800}
                                                    showAlerts={['error']}
                                                /> :
                                                <>
                                                    <Typography variant="body2" gutterBottom><b>Загрузка
                                                        фотографий...</b></Typography>
                                                    <CircularProgress/>
                                                </>
                                            }
                                        </Grid>
                                        <Grid item xs={12}
                                              style={{
                                                  textAlign: 'center'
                                              }}
                                        >
                                            {dropzoneAreaElementFilesInit ?
                                                <DropzoneArea
                                                    initialFiles={elementData.elementFiles}
                                                    acceptedFiles={['application/*']}
                                                    name="elementFiles"
                                                    onChange={(elementFiles) => handleElementChange({elementFiles: elementFiles})}
                                                    dropzoneText={"Если необходимо нажмите или перетащите сюда ФАЙЛЫ, НЕ фотографии. Не более 5 шт."}
                                                    filesLimit={5}
                                                    maxFileSize={52428800}
                                                    showAlerts={['error']}
                                                    useChipsForPreview
                                                    previewChipProps={{
                                                        label: "primary",
                                                        color: "primary"
                                                    }}
                                                /> :
                                                <>
                                                    <Typography variant="body2" gutterBottom><b>Загрузка
                                                        файлов...</b></Typography>
                                                    <CircularProgress/>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}
                                              style={{
                                                  paddingTop: '20px'
                                              }}
                                        >
                                            <Divider/>
                                            {
                                                dateSaveElement !== false &&
                                                <Typography variant="caption" display="block" gutterBottom
                                                            style={{
                                                                paddingTop: '20px'
                                                            }}
                                                >
                                                    {elementData.elementStatus === 4 && "Черновик от: " + dateSaveElement}
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={6}
                                              style={{
                                                  paddingTop: '20px'
                                              }}
                                        >
                                            <Divider/>
                                            {
                                                dateSaveElement !== false &&
                                                <Typography variant="caption" display="block" gutterBottom
                                                            style={{
                                                                paddingTop: '20px',
                                                                textAlign: "right"
                                                            }}
                                                >
                                                    {(elementData.elementStatus === 5 || elementData.elementStatus === 8) && "Опубликован:" + dateSaveElement}
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            {
                                                (elementData.elementStatus === 5 || elementData.elementStatus === 8 ?
                                                        <Button variant="contained" size="small"
                                                                style={{
                                                                    textShadow: 'none',
                                                                    backgroundImage: 'none',
                                                                    fontSize: '0.8rem',
                                                                    fontWeight: 400,
                                                                    textTransform: 'none',
                                                                }}
                                                                startIcon={<HighlightOffIcon
                                                                    style={{
                                                                        margin: '0px 0px 3px 0px'
                                                                    }}
                                                                />}
                                                                onClick={() => Promise.resolve(editElement(false))}
                                                                disabled={loading}
                                                        >Закрыть</Button>
                                                        :
                                                        <>
                                                            <Button variant="contained" size="small"
                                                                    style={{
                                                                        textShadow: 'none',
                                                                        backgroundImage: 'none',
                                                                        fontSize: '0.8rem',
                                                                        fontWeight: 400,
                                                                        textTransform: 'none',
                                                                    }}
                                                                    startIcon={
                                                                        (loadingElement && elementData.elementStatus === 4 ?
                                                                                <CircularProgress
                                                                                    size={18}
                                                                                    style={{
                                                                                        color: green[500],
                                                                                        margin: '0px 0px 3px 0px'
                                                                                    }}/>
                                                                                :
                                                                                <SaveIcon
                                                                                    style={{
                                                                                        margin: '0px 0px 3px 0px'
                                                                                    }}
                                                                                />
                                                                        )
                                                                    }
                                                                    onClick={() => Promise.resolve(saveElement())}
                                                                    disabled={loadingElement}
                                                            >Сохранить черновик</Button>
                                                        </>
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={6}
                                              style={{
                                                  textAlign: "right"
                                              }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="flex-end"
                                            >
                                                <Grid>
                                                    {
                                                        (elementData.elementStatus === 5 || elementData.elementStatus === 8 ?
                                                                <Button variant="contained" size="small" color="primary"
                                                                        style={{
                                                                            textShadow: 'none',
                                                                            backgroundImage: 'none',
                                                                            fontSize: '0.8rem',
                                                                            fontWeight: 400,
                                                                            textTransform: 'none',
                                                                        }}
                                                                        startIcon={
                                                                            (loadingElement && elementData.elementStatus !== 4 ?
                                                                                    <CircularProgress
                                                                                        size={18}
                                                                                        style={{
                                                                                            color: green[500],
                                                                                            margin: '0px 0px 3px 0px'
                                                                                        }}/>
                                                                                    :
                                                                                    <PublicIcon
                                                                                        style={{
                                                                                            margin: '0px 0px 3px 0px'
                                                                                        }}
                                                                                    />
                                                                            )
                                                                        }
                                                                        onClick={() => Promise.resolve(publicElement())}
                                                                        disabled={loadingElement}
                                                                >Обновить публикацию</Button>
                                                                :
                                                                <Typography variant="body2" gutterBottom
                                                                            style={{
                                                                                textShadow: 'none',
                                                                                backgroundImage: 'none',
                                                                                fontSize: '0.8rem',
                                                                                fontWeight: 400,
                                                                                textTransform: 'none',
                                                                            }}
                                                                >Заполните все поля
                                                                    по объекту и отзыву чтобы можно было их
                                                                    опубликовать.</Typography>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                                :
                                (!elementInit ?
                                    <>
                                        <StyledPaper
                                            style={{
                                                margin: '20px 0px 20px 0px'
                                            }}
                                        >
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}
                                                      style={{
                                                          textAlign: 'center'
                                                      }}
                                                >
                                                    <Typography variant="body2" gutterBottom><b>Загрузка
                                                        данных объекта...</b></Typography>
                                                    <CircularProgress/>
                                                </Grid>
                                            </Grid>
                                        </StyledPaper>
                                    </>
                                    :
                                    "")
                        )
                    }


                    <StyledPaper>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={12}
                                          style={{
                                              padding: '0 0 20px 0'
                                          }}
                                    >
                                        <Typography gutterBottom component="p" variant="h6"
                                                    color="textPrimary">Отзыв</Typography>
                                        <Typography variant="body2" gutterBottom>Для публикации отзыва необходимо
                                            заполнить все поля формы.
                                            Копирование отзывов с других сайтов запрещено.<br/>Нажмите на
                                            кнопку <b>"Сохранить"</b>, чтобы вернуться в любой момент и продолжить
                                            заполнение полей.</Typography>
                                    </Grid>
                                    {
                                        (parseInt(field.reviewStatus) === 6 || parseInt(field.reviewStatus) === 45) && dateSaveReview !== false &&
                                        <Grid item xs={12}
                                              style={{
                                                  margin: '-15px 0px 20px 0px'
                                              }}
                                        >
                                            <Alert variant="outlined" severity="info">
                                                Отзыв успешно сохранен: <b>{dateSaveReview}</b>, но не опубликован.
                                                Заполните
                                                все поля по объекту и отзыву, после нажмите "Опубликовать отзыв".
                                            </Alert>
                                        </Grid>
                                    }
                                    {
                                        (parseInt(field.reviewStatus) === 7 || parseInt(field.reviewStatus) === 9) && dateSaveReview !== false &&
                                        <Grid item xs={12}
                                              style={{
                                                  margin: '-15px 0px 20px 0px'
                                              }}
                                        >
                                            <Alert variant="outlined" severity="success">
                                                Отзыв успешно опубликован: <b>{dateSaveReview}</b>
                                            </Alert>
                                        </Grid>
                                    }
                                    <Grid
                                        style={{
                                            padding: '3px 10px 0 2px'
                                        }}
                                    >
                                        Оценка
                                    </Grid>
                                    <Grid>
                                        <Rating
                                            name="reviewRating"
                                            value={parseInt(valueRating)}
                                            precision={1}
                                            onChange={handleFieldChange}
                                            onChangeActive={(event, newHover) => {
                                                setRatingHover(newHover);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            padding: '3px 0 0 10px'
                                        }}
                                    >
                                        {valueRating !== null &&
                                        <span>{labels[hoverRating !== -1 ? hoverRating : valueRating]}</span>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    multiline
                                    minRows={2}
                                    maxRows={16}
                                    fullWidth
                                    variant="outlined"
                                    label="Достоинства"
                                    name="reviewDignity"
                                    value={field.reviewDignity}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    multiline
                                    minRows={2}
                                    maxRows={16}
                                    name="reviewDisadvantage"
                                    value={field.reviewDisadvantage}
                                    label="Недостатки"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    multiline
                                    minRows={2}
                                    maxRows={16}
                                    name="reviewComment"
                                    value={field.reviewComment}
                                    label="Комментарий"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}
                            >
                                {dropzoneAreaInit ?
                                    <DropzoneArea
                                        initialFiles={field.reviewAttach}
                                        acceptedFiles={['image/*']}
                                        name="reviewAttach"
                                        onChange={(reviewAttach) => handleFieldChange({"reviewAttach": reviewAttach})}
                                        dropzoneText={"Нажмите или перетащите сюда ваши фотографии. Не более 20 шт."}
                                        filesLimit={20}
                                        maxFileSize={52428800}
                                        showAlerts={['error']}
                                    /> :
                                    <>
                                        <Typography variant="body2" gutterBottom>Загрузка фотографий...</Typography>
                                        <CircularProgress/>
                                    </>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={4}
                                          style={{
                                              textAlign: "center"
                                          }}
                                    >
                                        {selectedValueSign !== "" &&
                                        <ListItem>
                                            <ListItemAvatar>
                                                {
                                                    user.userData !== undefined && user.userData.avatar !== undefined && user.userData.avatar !== "" && selectedValueSign !== "Anonymous" ?
                                                        <Avatar alt={selectedValueSign}
                                                                src={user.userData.avatar}/> :
                                                        <Avatar
                                                            alt={selectedValueSign}><PersonOutlineOutlinedIcon/></Avatar>
                                                }
                                            </ListItemAvatar>
                                            <ListItemText primary={selectedValueSign}/>
                                        </ListItem>
                                        }
                                        {selectedValueSign === "" &&
                                        <Typography variant="body2" gutterBottom>Подпись</Typography>
                                        }
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleClickOpenSign}
                                            style={{
                                                textShadow: 'none',
                                                backgroundImage: 'none',
                                                fontSize: '0.8rem',
                                                fontWeight: 400,
                                                textTransform: 'none',
                                            }}
                                        >
                                            Выбрать
                                        </Button>
                                        {
                                            user.userData !== undefined && signatures.length > 0 &&
                                            <SimpleDialog selectedValue={selectedValueSign} open={openSign}
                                                          onClose={handleCloseSign} signaturesValue={signatures}
                                                          avatarValue={user.userData.avatar}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2" gutterBottom>Вы можете опубликовать свой отзыв от
                                            своего: псевдонима, имени и фамилии или анонимно.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </StyledPaper>


                    <StyledPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      paddingTop: '20px'
                                  }}
                            >
                                <Divider/>
                                {
                                    dateSaveReview !== false &&
                                    <Typography variant="caption" display="block" gutterBottom
                                                style={{
                                                    paddingTop: '20px'
                                                }}
                                    >
                                        {reviewInit ? "Черновик сохранен" : "Опубликован"}: {dateSaveReview}
                                    </Typography>
                                }
                            </Grid>
                            {!elementData.elementPublic && <Grid item xs={12}>
                                <Alert variant="outlined" severity="info">
                                    Перед публикацией отзыва вам необходимо заполнить все данные по объекту и нажать кнопку "Сохранить черновик" у объекта.
                                </Alert>
                            </Grid>}
                            <Grid item xs={6}>
                                <Box style={{
                                    position: 'relative',
                                }}>
                                    <Button variant="contained" size="small"
                                            style={{
                                                textShadow: 'none',
                                                backgroundImage: 'none',
                                                fontSize: '0.8rem',
                                                fontWeight: 400,
                                                textTransform: 'none',
                                            }}
                                            startIcon={
                                                (loading ?
                                                        <CircularProgress
                                                            size={18}
                                                            style={{
                                                                color: green[500],
                                                                margin: '0px 0px 3px 0px'
                                                            }}/>
                                                        :
                                                        <SaveIcon
                                                            style={{
                                                                margin: '0px 0px 3px 0px'
                                                            }}
                                                        />
                                                )
                                            }
                                            onClick={() => Promise.resolve(saveReview())}
                                            disabled={loading || loadingPublicReview || !reviewInit}
                                    >Сохранить черновик</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={6}
                                  style={{
                                      textAlign: 'right'
                                  }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-end"
                                >
                                    <Grid>
                                        <Button variant="contained" size="small" color="primary"
                                                style={{
                                                    textShadow: 'none',
                                                    backgroundImage: 'none',
                                                    fontSize: '0.8rem',
                                                    fontWeight: 400,
                                                    textTransform: 'none',
                                                }}
                                                startIcon={
                                                    (loadingPublicReview ?
                                                            <CircularProgress
                                                                size={18}
                                                                style={{
                                                                    color: green[500],
                                                                    margin: '0px 0px 3px 0px'
                                                                }}/>
                                                            :
                                                            <PublicIcon
                                                                style={{
                                                                    margin: '0px 0px 3px 0px'
                                                                }}
                                                            />
                                                    )
                                                }
                                                disabled={loading || loadingPublicReview || !reviewInit || !elementData.elementPublic}
                                                onClick={() => Promise.resolve(publicReview())}
                                        >Опубликовать отзыв</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledPaper>


                </Grid>
            </Grid>
        </>
    );

}

Review.propTypes =
    {
        window: PropTypes.func,
    }
;

export default Review