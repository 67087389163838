import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {Helmet} from "react-helmet";
import {titles, drawerWidth, drawer} from "../services/auth";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledPaperLeftMenu = styled(Paper)(({theme}) => ({
    padding: theme.spacing(0),
    margin: 'auto',
}));
const StyledDrawerNav = styled('nav')(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    marginRight: "20px",
}));
const StyledIconButton = styled(IconButton)(({theme}) => ({
    [theme.breakpoints.up(960)]: {
        display: 'none',
    },
}));
const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
}));


const Notifications = (props) => {

    const {window} = props;
    const pathName = props.path
    const [valueTitle, setValueTitle] = useState()

    function setTitle() {
        titles.map((title, t) => (
            typeof pathName !== 'undefined' && pathName === title.link && valueTitle !== title.name ? setValueTitle(title.name) : ""
        ))
    }

    setTitle()

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const handleChange = (event) => {
        setNotifications({...notifications, [event.target.name]: event.target.checked});
        let formData = new FormData();
        formData.append(event.target.name, event.target.checked)
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/user-set-notifications.php",
            responseType: 'json',
        })
            .then(res => {
                setSnackbarMessage(res.data.severity, res.data.message)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    };

    const [notifications, setNotifications] = useState({
        changeMyRating: true,
        changingMyMana: true,
        myReviewIsSpam: true,
        newReviewsInElement: true,
        elementIsDraft: true,
        reviewIsDraft: true,
        votingMyReviews: true,
        newComment: true,
    });

    const [notificationsInit, setNotificationsInit] = useState(false);

    useEffect(() => {

        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/user-get-notifications.php",
            responseType: 'json',
        })
            .then(res => {
                setNotifications(res.data);
                setNotificationsInit(true);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }, []);

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    return (

        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{valueTitle}</title>
                <meta name="description" content={valueTitle}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Grid container>

                <Grid item xs={false} md={3}>
                    <StyledDrawerNav aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <StyledDrawer
                                container={container}
                                variant="temporary"
                                anchor="left"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </StyledDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <StyledPaperLeftMenu>{drawer}</StyledPaperLeftMenu>
                        </Hidden>
                    </StyledDrawerNav>
                </Grid>
                <Grid item xs={12} md={9}>

                    <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom component="h1" variant="h5"
                                            color="textPrimary">
                                    <StyledIconButton
                                        color="inherit"
                                        aria-label="show more"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            textTransform: 'none',
                                            margin: '-7px 0px 0px -10px'
                                        }}
                                    >
                                        <MenuIcon/>
                                    </StyledIconButton>

                                    {valueTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>Настройка оповещений с сайта.</Typography>
                            </Grid>
                        </Grid>
                    </StyledPaper>

                    {notificationsInit === false &&
                    <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>
                                <>
                                    <Typography variant="body2" gutterBottom><b>Загрузка оповещений...</b></Typography>
                                    <CircularProgress/>
                                </>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }

                    {notificationsInit === true &&
                    <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.votingMyReviews}
                                                onChange={handleChange}
                                                name="votingMyReviews"
                                                color="primary"
                                            />
                                        }
                                        label="Голосование за мои отзывы"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Когда пользователи отмечают в вашем отзыве "Отзыв полезен" или "Отзыв
                                        безполезен".
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.myReviewIsSpam}
                                                onChange={handleChange}
                                                name="myReviewIsSpam"
                                                color="primary"
                                            />
                                        }
                                        label="Мой отзыв считают за SPAM"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Отзывы, которые пользователями отмечают как SPAM, со временем могут быть
                                        скрыты или удалены с сайта.
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.newReviewsInElement}
                                                onChange={handleChange}
                                                name="newReviewsInElement"
                                                color="primary"
                                            />
                                        }
                                        label="Новые отзывы в моих объектах"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Важно для оперативного отслеживания и реакции на новые отзывы.
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.changeMyRating}
                                                onChange={handleChange}
                                                name="changeMyRating"
                                                color="primary"
                                            />
                                        }
                                        label="Изменение моего рейтинга"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Важный показатель вашей активности на сайте. Рейтинг можно повысить добавляя
                                        новые объекты, отзывы или за голосование в отзывах.
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.changingMyMana}
                                                onChange={handleChange}
                                                name="changingMyMana"
                                                color="primary"
                                            />
                                        }
                                        label="Изменение моего авторитета"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Авторитет влияет на вес вашего голоса за голосование в отзывах. Авторитет меньше
                                        1 ведет к блокировке голосование за отзывы.
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.elementIsDraft}
                                                onChange={handleChange}
                                                name="elementIsDraft"
                                                color="primary"
                                            />
                                        }
                                        label="Объекты в статусе черновик"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Напоминание 1 раз в неделю об объектах, которые находятся в статусе Черновик.
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.reviewIsDraft}
                                                onChange={handleChange}
                                                name="reviewIsDraft"
                                                color="primary"
                                            />
                                        }
                                        label="Отзывы в статусе черновик"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Напоминание 1 раз в неделю об отзывах, которые находятся в статусе Черновик.
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={notifications.newComment}
                                                onChange={handleChange}
                                                name="newComment"
                                                color="primary"
                                            />
                                        }
                                        label="Новый комментарий на мой отзыв"
                                    />
                                    <Typography variant="body2" component="p"
                                                color="textPrimary"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    margin: '0px 0 15px 0'
                                                }}
                                    >
                                        Оповещение о новом комментарии в вашем отзыве от официального представителя. (Официальный представитель имеет специальную отметку в имени)
                                    </Typography>
                                </FormGroup>

                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }


                </Grid>

            </Grid>
        </>
    );

}

Notifications.propTypes = {
    window: PropTypes.func,
};

export default Notifications