import React, {useState, useEffect} from 'react';
import {getUserProfile, setUser, titles, drawerWidth, drawer} from "../services/auth"
import {styled} from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import Avatar from '@material-ui/core/Avatar';
import SaveIcon from '@material-ui/icons/Save';
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import axios from "axios";
import {Helmet} from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledPaperLeftMenu = styled(Paper)(({theme}) => ({
    padding: theme.spacing(0),
    margin: 'auto',
}));
const StyledDrawerNav = styled('nav')(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    marginRight: "20px",
}));
const StyledIconButton = styled(IconButton)(({theme}) => ({
    [theme.breakpoints.up(960)]: {
        display: 'none',
    },
}));
const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
}));
const StyledRatingUserText = styled('span')(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        fontSize: "0.7rem"
    },
}));
const StyledChipRatingUser = styled(Chip)(({theme}) => ({
    width: "4rem",
    height: "1rem",
    fontSize: '0.8rem',
    padding: '2px 0 0 0',
    margin: '-3px 0 0 0',
    backgroundColor: '#D9D9D9FF',
    color: 'black',
    [theme.breakpoints.down('md')]: {
        fontSize: "0.6rem",
        width: "2.4rem",
        padding: '0 0 0 0',
    },
}));

const Profile = (props) => {

    const {window} = props;
    const pathName = props.path
    const [valueTitle, setValueTitle] = useState()

    function setTitle() {
        titles.map((title, t) => (
            typeof pathName !== 'undefined' && pathName === title.link && valueTitle !== title.name ? setValueTitle(title.name) : ""
        ))
    }

    setTitle()

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    // Запрос данных полсе отображения страницы на клиенте
    const [userProfileResolve, setUserProfileResolve] = useState({
        avatar: false,
        dateRegister: "",
        email: "",
        lastName: "",
        name: "",
        nickname: "",
        secondName: "",
    })
    const [userProfile, setUserProfile] = useState(false)
    const [userProfileReload, setUserProfileReload] = useState(1)


    useEffect(() => {
        setUserProfileResolve(getUserProfile);
    }, [userProfileReload])

    let promise = new Promise(function (resolve) {
        resolve(userProfileResolve);
    });
    promise.then(function (result) {
            setUserProfile(result)
        }
    );

    // Сохранение данных, введенных клиентом
    function setUserFieldsProfile(field, value) {

        let values;
        if (value === null || value === undefined) {
            return;
        }
        if (field === "avatar") {
            let formData = new FormData();
            formData.append(
                field,
                value
            );
            formData.append(
                "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
            );
            formData.append(
                "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
            );
            formData.append(
                "avatar", "update",
            );
            values = Object.assign(formData);
        } else {
            values = JSON.stringify(Object.assign({
                [field]: value,
            }, JSON.parse(localStorage.getItem("gatsbyUser"))));
        }

        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/user-set-fields-profile.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data !== undefined) {
                    if (field === "avatar") {
                        setValueNicknameUpdate('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                        if (res.data.user.avatar) {

                            // console.log(userProfileResolve);
                            //
                            // setUserProfile({
                            //     ...userProfile, avatar: res.data.user.avatar
                            // });

                            // let userProfileCopy = {};
                            // userProfileCopy = userProfile
                            // userProfileCopy.avatar = res.data.user.avatar;
                            // setUserProfileResolve(userProfileCopy);
                            setUserProfileReload(userProfileReload+1)
                        }
                    }
                    if (field === "name") {
                        setValueNameUpdate('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                    }
                    if (field === "nickname") {
                        setValueNicknameUpdate('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                    }
                    if (field === "email-new") {
                        setValueEmailCodeConfirmHelpSteps(emailConfirmStep1);
                        setValueEmailHelpSteps(valueEmailHelpSteps);
                        if (res.data.type !== "error") {
                            setValueEmailCodeConfirmHelpSteps('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                        } else {
                            setValueEmailHelpSteps('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                        }
                    }
                    if (field === "email-code-confirm") {
                        setValueEmailHelpSteps(valueEmailHelpSteps);
                        setValueEmailCodeConfirmHelpSteps('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                        if (res.data.type !== "error") {
                            setFieldEmail(false);
                            setValueEmailCodeConfirmHelpSteps(emailConfirmStep1);
                            setValueEmailHelpSteps('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                            setUser(res.data.user)
                        }
                    }
                    if (field[0] === "password" && field[1] === "password-new" && field[2] === "password-new-confirm") {
                        if (res.data.type !== "error") {
                            setFieldPassword(false);
                        }
                        setValuePasswordNewConfirmUpdate('<span style="color: ' + res.data.color + ';">' + res.data.message + '</span>');
                    }
                } else {
                    return false;
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    // avatar
    function handleChangeAvatar(e) {
        setUserFieldsProfile("avatar", e.target.files[0]);
    }

    // nickname
    const valueNicknameHelpDefault = "Регистрация: " + (userProfile !== undefined && userProfile.dateRegister !== undefined ? userProfile.dateRegister : "") + "";
    const [valueNickname, setValueNickname] = useState(false);

    function handleChangeNickname(e) {
        let nickname = e.target.value.trim();
        setValueNickname(nickname);
        const regex = /^\w{2,12}$/;
        setFieldValidNickname(!regex.test(nickname));
    }

    const valueNicknameHelp = "От 2 до 12 латинских символов и цифр"
    const [fieldNickname, setFieldNickname] = useState(false);
    const [fieldValidNickname, setFieldValidNickname] = useState(false);

    function setNickname(field) {
        setValueNicknameUpdate(false)
        setFieldNickname(field === true ? false : true);
        if (valueNickname && field === true) {
            setUserFieldsProfile("nickname", valueNickname);
        }
    }

    const [valueNicknameUpdate, setValueNicknameUpdate] = useState(false);

    // name
    const valueNameHelpDefault = "Чтобы показывать вместо Псевдонима";
    const [valueName, setValueName] = useState(false);

    function handleChangeName(e) {
        let name = e.target.value;
        setValueName(name);
        const regex = /^[\p{Alpha}\p{Nd}\s]{2,100}$/gu
        setFieldValidName(!regex.test(name));
    }

    const valueNameHelp = "От 2 до 100 символов"
    const [fieldName, setFieldName] = useState(false);
    const [fieldValidName, setFieldValidName] = useState(false);

    function setName(field) {
        setValueNameUpdate(false)
        setFieldName(field === true ? false : true);
        if (valueName && field === true) {
            setUserFieldsProfile("name", valueName);
        }
    }

    const [valueNameUpdate, setValueNameUpdate] = useState(false);

    // E-mail
    // подсказки по шагам смены E-mail
    const emailStep1 = "Введите новый E-mail и нажмите отправить";
    const valueEmailHelp = "Укажите вернвый E-mail";
    // проверка введенного значения
    const [valueEmail, setValueEmail] = useState(false);
    // включение поля ввода
    const [fieldEmail, setFieldEmail] = useState(false);
    // выделение красным полвя ввода
    const [fieldValidEmail, setFieldValidEmail] = useState(false);

    // проверка правильности ввода E-mail
    function handleChangeEmail(e) {
        setValueEmailHelpSteps(emailStep1);
        let email = e.target.value.trim();
        setValueEmail(email);
        const regex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/
        setFieldValidEmail(!regex.test(email));
    }

    // отправка проверочного кода на новый E-mail
    function setEmailCodeConfirm() {
        if (valueEmail) {
            setUserFieldsProfile("email-new", valueEmail);
        }
    }

    // подсказки по шагам подтверждения E-mail
    const emailConfirmStep1 = "Из письма на новый E-mail";
    const valueEmailCodeConfirmHelp = "Укажите 12 латинских символов и цифр";
    const [valueEmailHelpSteps, setValueEmailHelpSteps] = useState("");
    const [valueEmailCodeConfirm, setValueEmailCodeConfirm] = useState(false);
    const [valueEmailCodeConfirmHelpSteps, setValueEmailCodeConfirmHelpSteps] = useState(emailConfirmStep1);
    const [fieldValidEmailCodeConfirm, setFieldValidEmailCodeConfirm] = useState(false);

    // проверка правильности ввода кода подтверждения
    function handleChangeEmailCodeConfirm(e) {
        let emailCodeConfirm = e.target.value.trim();
        setValueEmailCodeConfirm(emailCodeConfirm);
        const regex = /^[a-zA-Z0-9]{12}$/
        setFieldValidEmailCodeConfirm(!regex.test(emailCodeConfirm));
        setValueEmailCodeConfirmHelpSteps(emailConfirmStep1);
    }

    // получение кода, обновление E-mail
    function setEmail(field) {
        if (field === true) {
            if (valueEmailCodeConfirm) {
                setUserFieldsProfile("email-code-confirm", valueEmailCodeConfirm);
            }
        } else {
            setFieldEmail(true);
        }
    }

    // password
    const [valuePassword, setValuePassword] = useState(false);
    const valuePasswordHelpDefault = "Введите ваш текущий пароль";
    const valuePasswordHelpError = "Указан неверный текущий пароль";
    const [valuePasswordError, setValuePasswordError] = useState(false);

    function handleChangePassword(e) {
        let password = e.target.value;
        setValuePassword(password);
        setValuePasswordNewConfirmUpdate(false);
        if (password.length < 6) {
            setValuePasswordError(true);
        } else {
            setValuePasswordError(false);
        }
    }

    // new password
    const valuePasswordNewHelpDefault = "От 6 символов";
    const [fieldValidPassword, setFieldValidPassword] = useState(false);
    const [valuePasswordNew, setValuePasswordNew] = useState(false);

    function handleChangePasswordNew(e) {
        let passwordNew = e.target.value;
        setValuePasswordNew(passwordNew);
        const regex = /^[\p{L}\p{M}\p{Z}\p{N}\p{P}\p{C}\p{S}]{6,}$/gu
        setFieldValidPassword(!regex.test(passwordNew));
    }

    // new password confirm
    const valuePasswordNewConfirmHelpDefault = "Должен совпадать с новым паролем";
    const [fieldValidPasswordNewConfirm, setFieldValidPasswordNewConfirm] = useState(false);
    const [valuePasswordNewConfirm, setValuePasswordNewConfirm] = useState(false);

    function handleChangePasswordNewConfirm(e) {
        let passwordNewConfirm = e.target.value;
        setValuePasswordNewConfirm(passwordNewConfirm);
        if (passwordNewConfirm !== valuePasswordNew) {
            setFieldValidPasswordNewConfirm(true);
        } else {
            setFieldValidPasswordNewConfirm(false);
        }
    }

    // check
    const [fieldPassword, setFieldPassword] = useState(false);

    function setPassword(field) {
        setFieldPassword(field === true ? false : true);
        if (valuePassword && field === true) {
            setFieldPassword(true);
            setUserFieldsProfile(["password", "password-new", "password-new-confirm"], [valuePassword, valuePasswordNew, valuePasswordNewConfirm]);
        }
    }

    const [valuePasswordNewConfirmUpdate, setValuePasswordNewConfirmUpdate] = useState(false);

    return (

        <>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{valueTitle}</title>
                <meta name="description" content={valueTitle}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Grid container>

                <Grid item xs={false} md={3}>
                    <StyledDrawerNav aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <StyledDrawer
                                container={container}
                                variant="temporary"
                                anchor="left"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </StyledDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <StyledPaperLeftMenu>{drawer}</StyledPaperLeftMenu>
                        </Hidden>
                    </StyledDrawerNav>
                </Grid>
                <Grid item xs={12} md={9}>

                    <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom component="h1" variant="h5"
                                            color="textPrimary">
                                    <StyledIconButton
                                        color="inherit"
                                        aria-label="show more"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            textTransform: 'none',
                                            margin: '-7px 0px 0px -10px'
                                        }}
                                    >
                                        <MenuIcon/>
                                    </StyledIconButton>

                                    {valueTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>Вы можете выбрать, что указывать, ваш псевдоним
                                    или имя и фамилию в отзывах.</Typography>
                            </Grid>
                        </Grid>
                    </StyledPaper>

                    {isNaN(parseInt(userProfile.id)) &&
                    <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>
                                <>
                                    <Typography variant="body2" gutterBottom><b>Загрузка профиля...</b></Typography>
                                    <CircularProgress/>
                                </>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }

                    {parseInt(userProfile.id) > 0 &&
                    <Grid
                        spacing={3}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="stretch"
                    >
                        <Grid item xs={3}
                              style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                              }}
                        >
                            <StyledPaper
                                style={{
                                    margin: '20px 0px 20px 0px',
                                }}
                            >
                                <StyledRatingUserText>Авторитет</StyledRatingUserText>
                                <br/>
                                <StyledChipRatingUser
                                    size="small"
                                    label={parseFloat(userProfile.mana)}
                                />
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={3}
                              style={{
                                  textAlign: "center",
                                  fontWeight: "bold"
                              }}
                        >
                            <StyledPaper
                                style={{
                                    margin: '20px 0px 20px 0px'
                                }}
                            >
                                <StyledRatingUserText>Рейтинг</StyledRatingUserText>
                                <br/>
                                <StyledChipRatingUser
                                    size="small"
                                    label={parseFloat(userProfile.rating)}
                                />
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={3}
                              style={{
                                  textAlign: "center",
                                  fontWeight: "bold"
                              }}
                        >
                            <StyledPaper
                                style={{
                                    margin: '20px 0px 20px 0px'
                                }}
                            >
                                <StyledRatingUserText>Объектов</StyledRatingUserText>
                                <br/>
                                {parseInt(userProfile.elementsDraft) > 0 ?
                                    <StyledChipRatingUser
                                        size="small"
                                        label={parseInt(userProfile.elementsPublic) + "(" + parseInt(userProfile.elementsDraft) + ")"}
                                    />
                                    :
                                    <StyledChipRatingUser
                                        size="small"
                                        label={parseInt(userProfile.elementsPublic)}
                                    />
                                }
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={3}
                              style={{
                                  textAlign: "center",
                                  fontWeight: "bold"
                              }}
                        >
                            <StyledPaper
                                style={{
                                    margin: '20px 0px 20px 0px'
                                }}
                            >
                                <StyledRatingUserText>Отзывов</StyledRatingUserText>
                                <br/>
                                {parseInt(userProfile.reviewsDraft) > 0 ?
                                    <StyledChipRatingUser
                                        size="small"
                                        label={parseInt(userProfile.reviewsPublic) + "(" + parseInt(userProfile.reviewsDraft) + ")"}
                                    />
                                    :
                                    <StyledChipRatingUser
                                        size="small"
                                        label={parseInt(userProfile.reviewsPublic)}
                                    />
                                }
                            </StyledPaper>
                        </Grid>
                    </Grid>
                    }
                    {parseInt(userProfile.id) > 0 &&
                    <StyledPaper
                        style={{
                            margin: '0px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    marginBottom: '30px',
                                }}>
                                    {
                                        (userProfile.avatar ?
                                            <Avatar
                                                style={{
                                                    marginTop: '8px',
                                                }}
                                                alt={userProfile.nickname}
                                                src={userProfile.avatar}/> :
                                            <Avatar
                                                style={{
                                                    marginTop: '8px',
                                                }}
                                                alt={userProfile.nickname}><PersonOutlineOutlinedIcon/></Avatar>)
                                    }
                                    <input
                                        onChange={handleChangeAvatar}
                                        accept="image/*"
                                        style={{
                                            display: 'none'
                                        }}
                                        id="avatar"
                                        type="file"
                                    />
                                    <label htmlFor="avatar">
                                        <IconButton
                                            component="span"
                                            style={{
                                                marginTop: "22px",
                                                marginRight: "22px"
                                            }}
                                            aria-label="Avatar"
                                            size="small"
                                        >
                                            <PhotoCamera/>
                                        </IconButton>
                                    </label>


                                    <FormControl fullWidth error={fieldValidNickname}>
                                        <InputLabel htmlFor="nickname">Псевдоним</InputLabel>
                                        <Input
                                            onChange={handleChangeNickname}
                                            id="nickname"
                                            type="text"
                                            value={valueNickname !== false ? valueNickname.trim() : "" + userProfile.nickname + ""}
                                            disabled={!fieldNickname}
                                            variant="standard"
                                            endAdornment={
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => Promise.resolve(setNickname(fieldNickname))}
                                                >
                                                    {
                                                        (!fieldNickname ?
                                                                <IconButton
                                                                    disabled={fieldValidNickname}
                                                                    aria-label="nickname"
                                                                    size="small"><EditIcon
                                                                    fontSize="inherit"/></IconButton> :
                                                                <Button variant="contained"
                                                                        size="small"
                                                                        style={{
                                                                            textShadow: 'none',
                                                                            backgroundImage: 'none',
                                                                            fontSize: '0.6rem',
                                                                            fontWeight: 400,
                                                                            textTransform: 'none',
                                                                            margin: "0px 0px 10px 0px",
                                                                        }}
                                                                        startIcon={<SaveIcon
                                                                            color={fieldValidNickname ? "error" : "primary"}
                                                                            fontSize="inherit"
                                                                            size="small"
                                                                            style={{
                                                                                margin: "0px 0px 0px 0px",
                                                                            }}
                                                                        />}
                                                                >Сохранить</Button>
                                                        )
                                                    }
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="nickname" dangerouslySetInnerHTML={{
                                            __html: fieldValidNickname ?
                                                valueNicknameHelp : valueNicknameUpdate !== false ?
                                                    valueNicknameUpdate : valueNicknameHelpDefault
                                        }}/>
                                    </FormControl>
                                </Box>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    marginBottom: '40px',
                                }}>
                                    <FormControl fullWidth error={fieldValidName}>
                                        <InputLabel htmlFor="name">Имя Фамилия</InputLabel>
                                        <Input
                                            onChange={handleChangeName}
                                            id="name"
                                            type="text"
                                            value={valueName !== false ? valueName : "" + userProfile.name + " " + userProfile.lastName + " " + userProfile.secondName + ""}
                                            disabled={!fieldName}
                                            variant="standard"
                                            endAdornment={
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => Promise.resolve(setName(fieldName))}>
                                                    {
                                                        (!fieldName ? <IconButton
                                                                    disabled={fieldValidName}
                                                                    aria-label="name"
                                                                    size="small"><EditIcon
                                                                    fontSize="inherit"
                                                                /></IconButton> :
                                                                <Button variant="contained" size="small"
                                                                        style={{
                                                                            textShadow: 'none',
                                                                            backgroundImage: 'none',
                                                                            fontSize: '0.6rem',
                                                                            fontWeight: 400,
                                                                            textTransform: 'none',
                                                                            margin: "0px 0px 10px 0px",
                                                                        }}
                                                                        startIcon={<SaveIcon
                                                                            color={fieldValidNickname ? "error" : "primary"}
                                                                            fontSize="inherit"
                                                                            size="small"
                                                                            style={{
                                                                                margin: "0px 0px 0px 0px",
                                                                            }}
                                                                        />}
                                                                >Сохранить</Button>
                                                        )
                                                    }
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="name" dangerouslySetInnerHTML={{
                                            __html: fieldValidName ?
                                                valueNameHelp : valueNameUpdate !== false ?
                                                    valueNameUpdate : valueNameHelpDefault
                                        }}/>
                                    </FormControl>
                                </Box>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    marginBottom: (!fieldEmail ? '40px' : '10px'),
                                }}>
                                    <FormControl fullWidth error={fieldValidEmail}>
                                        <InputLabel htmlFor="email">E-mail</InputLabel>
                                        <Input
                                            onChange={handleChangeEmail}
                                            id="email"
                                            type="text"
                                            value={valueEmail !== false ? valueEmail : "" + userProfile.email + ""}
                                            disabled={!fieldEmail}
                                            variant="standard"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {
                                                        (!fieldEmail ?
                                                                <IconButton
                                                                    aria-label="email"
                                                                    size="small"
                                                                    onClick={() => Promise.resolve(setEmail(fieldEmail))}>
                                                                    <EditIcon
                                                                        fontSize="inherit"
                                                                        size="small"
                                                                        style={{
                                                                            margin: "0px 0px 0px 0px",
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                                :
                                                                <IconButton
                                                                    aria-label="email"
                                                                    size="small"
                                                                    onClick={() => Promise.resolve(setEmailCodeConfirm(fieldEmail))}>
                                                                    <SendIcon color="primary"
                                                                              fontSize="inherit"
                                                                              size="small"
                                                                              style={{
                                                                                  margin: "0px 0px 0px 0px",
                                                                              }}
                                                                    />
                                                                </IconButton>
                                                        )
                                                    }
                                                </InputAdornment>
                                            }
                                        />
                                        {/*{(fieldEmail ?*/}
                                        {/*    <FormHelperText id="email"*/}
                                        {/*                    dangerouslySetInnerHTML={{__html: fieldValidEmail ? valueEmailHelp : valueEmailHelpSteps}}/> : '')}*/}
                                        <FormHelperText id="email" dangerouslySetInnerHTML={{
                                            __html: valueEmailHelpSteps ?
                                                valueEmailHelpSteps : fieldEmail ?
                                                    valueEmailHelp : valueEmailHelpSteps
                                        }}/>
                                    </FormControl>
                                </Box>
                                {
                                    (fieldEmail ?
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            marginBottom: '40px',
                                        }}>
                                            <FormControl fullWidth error={fieldValidEmailCodeConfirm}>
                                                <InputLabel htmlFor="email-code-confirm">Код
                                                    подтверждения</InputLabel>
                                                <Input
                                                    onChange={handleChangeEmailCodeConfirm}
                                                    id="email-code-confirm"
                                                    type="text"
                                                    disabled={!fieldEmail}
                                                    variant="standard"
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                            onClick={() => Promise.resolve(setEmail(fieldEmail))}
                                                        >
                                                            <IconButton
                                                                disabled={fieldValidEmailCodeConfirm}
                                                                aria-label="email-code-confirm"
                                                                size="small"></IconButton>
                                                            <Button variant="contained"
                                                                    size="small"
                                                                    style={{
                                                                        textShadow: 'none',
                                                                        backgroundImage: 'none',
                                                                        fontSize: '0.6rem',
                                                                        fontWeight: 400,
                                                                        textTransform: 'none',
                                                                        margin: "0px 0px 10px 0px",
                                                                    }}
                                                                    startIcon={<SaveIcon
                                                                        color={fieldValidEmailCodeConfirm ? "error" : "primary"}
                                                                        fontSize="inherit"
                                                                        size="small"
                                                                        style={{
                                                                            margin: "0px 0px 0px 0px",
                                                                        }}
                                                                    />}
                                                            >Сохранить</Button>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText id="email-code-confirm"
                                                                dangerouslySetInnerHTML={{__html: fieldValidEmailCodeConfirm ? valueEmailCodeConfirmHelp : valueEmailCodeConfirmHelpSteps}}/>
                                            </FormControl>
                                        </Box>
                                        : '')
                                }


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    marginBottom: '10px',
                                }}>
                                    <FormControl fullWidth error={valuePasswordError}>
                                        <InputLabel
                                            htmlFor="password">{(!fieldPassword ? "Сменить пароль" : "Текущий пароль")}</InputLabel>
                                        <Input
                                            disabled={!fieldPassword}
                                            onChange={handleChangePassword}
                                            id="password"
                                            type='password'
                                            variant="standard"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="password"
                                                        size="small"
                                                        onClick={() => Promise.resolve(setPassword(fieldPassword))}>
                                                        {
                                                            (!fieldPassword ? <EditIcon
                                                                        fontSize="inherit"
                                                                    />
                                                                    : ''
                                                            )
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="password" dangerouslySetInnerHTML={{
                                            __html: !fieldPassword && !valuePasswordNewConfirmUpdate ? "" : valuePasswordNewConfirmUpdate !== false ?
                                                valuePasswordNewConfirmUpdate : valuePasswordHelpError ? valuePasswordHelpError : valuePasswordHelpDefault
                                        }}/>
                                    </FormControl>
                                </Box>
                                {
                                    (fieldPassword ?
                                        <>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                marginBottom: '10px',
                                            }}>
                                                <FormControl fullWidth error={fieldValidPassword}>
                                                    <InputLabel htmlFor="password-new">Новый
                                                        пароль</InputLabel>
                                                    <Input
                                                        onChange={handleChangePasswordNew}
                                                        id="password-new"
                                                        type='password'
                                                        variant="standard"
                                                    />
                                                    <FormHelperText id="password"
                                                                    dangerouslySetInnerHTML={{__html: valuePasswordNewHelpDefault}}/>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                marginBottom: '40px',
                                            }}>
                                                <FormControl fullWidth
                                                             error={fieldValidPasswordNewConfirm}>
                                                    <InputLabel htmlFor="password-new-confirm">Подтвердите
                                                        новый пароль</InputLabel>
                                                    <Input
                                                        onChange={handleChangePasswordNewConfirm}
                                                        id="password-new-confirm"
                                                        type='password'
                                                        variant="standard"
                                                        endAdornment={
                                                            <InputAdornment
                                                                position="end"
                                                                onClick={() => Promise.resolve(setPassword(fieldPassword))}
                                                            >
                                                                <IconButton
                                                                    disabled={fieldValidPasswordNewConfirm}
                                                                    aria-label="password-new-confirm"
                                                                    size="small"></IconButton>
                                                                {
                                                                    (!fieldPassword ?
                                                                            '' :
                                                                            <Button variant="contained" size="small"
                                                                                    style={{
                                                                                        textShadow: 'none',
                                                                                        backgroundImage: 'none',
                                                                                        fontSize: '0.6rem',
                                                                                        fontWeight: 400,
                                                                                        textTransform: 'none',
                                                                                        margin: "0px 0px 10px 0px",
                                                                                    }}
                                                                                    startIcon={<SaveIcon
                                                                                        color={fieldValidPasswordNewConfirm || valuePasswordError ? "error" : "primary"}
                                                                                        fontSize="inherit"
                                                                                        size="small"
                                                                                        style={{
                                                                                            margin: "0px 0px 0px 0px",
                                                                                        }}
                                                                                    />}
                                                                            >Сохранить</Button>
                                                                    )
                                                                }
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText id="password-new-confirm"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: valuePasswordNewConfirmHelpDefault
                                                                    }}/>
                                                </FormControl>
                                            </Box>
                                        </>

                                        : '')
                                }

                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }

                </Grid>
            </Grid>

        </>
    );

}

Profile.propTypes = {
    window: PropTypes.func,
};

export default Profile