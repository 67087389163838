import React from 'react';

const Test = () => {

    return (
        <>
            :)
        </>
    );

}

export default Test