import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import { navigate } from "gatsby"
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Helmet} from "react-helmet";
import {titles, drawerWidth, drawer} from "../services/auth";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Links from "@material-ui/core/Link";
import Rating from "@material-ui/lab/Rating";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CardActions from "@material-ui/core/CardActions";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledPaperLeftMenu = styled(Paper)(({theme}) => ({
    padding: theme.spacing(0),
    margin: 'auto',
}));
const StyledDrawerNav = styled('nav')(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    marginRight: "20px",
}));
const StyledIconButton = styled(IconButton)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));
const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
}));
const StyledCardContent = styled(CardContent)(({theme}) => ({
    flexGrow: 1,
}));
const StyledRatingBoxDiv = styled('div')(({theme}) => ({
    padding: 0,
    margin: 0,
}));
const StyledCard = styled(Card)(({theme}) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
const StyledPaginationBox = styled(Box)(({theme}) => ({
    marginTop: theme.spacing(2),
    justifyContent: "center",
    display: 'flex'
}));

let q = "";
let code = "";
let action = "";
if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search);
    q = queryParams.get('q') === null ? "" : queryParams.get('q');
    code = queryParams.get('code') === null ? false : queryParams.get('code');
    action = queryParams.get('action') === null ? false : queryParams.get('action');
}

const Elements = (props) => {

    const {window} = props;
    const pathName = props.path
    const [valueTitle, setValueTitle] = useState()

    function setTitle() {
        titles.map((title, t) => (
            typeof pathName !== 'undefined' && pathName === title.link && valueTitle !== title.name ? setValueTitle(title.name) : ""
        ))
    }

    setTitle()

    const [page, setPage] = useState(1);
    const rowsPerPage = 6;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [elementsData, setElementsData] = useState([]);
    const [elementsInit, setElementsInit] = useState(false);
    const [elementsUpdate, setElementsUpdate] = useState(null);
    const [elementsDataFilter, setElementsDataFilter] = useState([]);
    const [elementsLoading, setElementsLoading] = useState(true);


    function activationRepresentative() {
        if (action === "representative" && code.length === 40) {
            let formData = new FormData();
            formData.append(
                "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
            );
            formData.append(
                "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
            );
            formData.append(
                "code", code,
            );
            axios({
                method: "POST",
                data: Object.assign(formData),
                url: "https://api.ocenivay.com/api/representative-activation.php",
                responseType: 'json',
            })
                .then(res => {
                    action = "";
                    code = "";
                    setElementsUpdate(1);
                    setSnackbarMessage(res.data.severity, res.data.message);
                    navigate("/app/elements")
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                });
        }
    }

    function search(q) {
        if (q.target !== undefined) {
            q = q.target.value;
        }
        if (q !== undefined) {
            setSearchVal(q);
            let lowerCase = q.toLowerCase();
            let resultSearch = [];
            elementsData.filter(row => {
                setPage(1);
                if (row.name.toLowerCase().includes(lowerCase.toLowerCase())) {
                    resultSearch.push(row);
                }
                return true;
            });
            if (lowerCase === "") {
                setElementsDataFilter(elementsData);
            } else {
                setElementsDataFilter(resultSearch);
            }
        } else {
            setPage(1);
            setElementsDataFilter(elementsData);
        }
    }

    const [searchVal, setSearchVal] = useState("");

    function clearSearch() {
        setSearchVal("");
        search("");
    }

    useEffect(() => {

        let values;
        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        values = Object.assign(formData);
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/elements-get.php",
            responseType: 'json',
        })
            .then(res => {
                setElementsLoading(false)
                setElementsData(res.data)
                setElementsDataFilter(res.data)
                setElementsInit(true)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementsUpdate]);

    useEffect(() => {
        setSearchVal(q);
        search(q);
        activationRepresentative();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementsData]);

    function editElement(elementId, reviewId) {
        document.location.href = "/app/review?elementId=" + elementId + "&reviewId="+reviewId;
    }

    function deleteElement(elementId) {
        let values;
        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        formData.append(
            "elementId", elementId,
        );
        values = Object.assign(formData);
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/delete.php",
            responseType: 'json',
        })
            .then(res => {
                setElementsUpdate(elementId);
                setSnackbarMessage(res.data.severity, res.data.message);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    function viewElement(url) {
        var a = document.createElement('a');
        a.target = "_blank";
        a.href = url;
        a.click();
    }

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    return (

        <>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{valueTitle}</title>
                <meta name="description" content={valueTitle}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Grid container>

                <Grid item xs={false} md={3}>
                    <StyledDrawerNav aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <StyledDrawer
                                container={container}
                                variant="temporary"
                                anchor="left"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </StyledDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <StyledPaperLeftMenu>{drawer}</StyledPaperLeftMenu>
                        </Hidden>
                    </StyledDrawerNav>
                </Grid>
                <Grid item xs={12} md={9}>

                    <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom component="h1" variant="h5"
                                            color="textPrimary">
                                    <StyledIconButton
                                        color="inherit"
                                        aria-label="show more"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            textTransform: 'none',
                                            margin: '-7px 0px 0px -10px'
                                        }}
                                    >
                                        <MenuIcon/>
                                    </StyledIconButton>

                                    {valueTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>Список всех ваших объектов: товары, компании,
                                    услуги. Для добавления нового объекта воспользуйтесь поиском выше.</Typography>
                            </Grid>
                        </Grid>
                    </StyledPaper>

                    {elementsLoading &&
                    <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>
                                <>
                                    <Typography variant="body2" gutterBottom><b>Загрузка объектов...</b></Typography>
                                    <CircularProgress/>
                                </>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }

                    {elementsData.length > 6 && <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>


                                <FormControl fullWidth>
                                    <InputLabel
                                        style={{
                                            fontSize: '0.9rem'
                                        }}
                                        htmlFor="search">Поиск среди моих объектов</InputLabel>
                                    <Input
                                        style={{
                                            fontSize: '0.9rem'
                                        }}
                                        onChange={search}
                                        id="search"
                                        value={searchVal}
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                onClick={clearSearch}>
                                                <IconButton
                                                    aria-label="name"
                                                    size="small"><ClearIcon
                                                    fontSize="inherit"
                                                /></IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <StyledPaginationBox>
                                    <Pagination
                                        count={Math.ceil(elementsDataFilter.length / rowsPerPage)}
                                        rowsPerPage={parseInt(rowsPerPage)}
                                        page={Math.ceil(page)}
                                        onChange={handleChangePage}
                                        showLastButton={(Math.ceil(elementsDataFilter.length / rowsPerPage) > rowsPerPage ? true : false)} // последняя страница true - показать
                                        showFirstButton={(Math.ceil(elementsDataFilter.length / rowsPerPage) > rowsPerPage ? true : false)} // первая страница true - показать
                                        variant="outlined" shape="rounded" color="primary"
                                        size="small"
                                    />
                                </StyledPaginationBox>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }

                    {
                        elementsData.length > 0 && elementsInit && elementsDataFilter.length === 0 &&
                        <StyledPaper
                            style={{
                                margin: '20px 0px 20px 0px'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    По запросу ваши объекты не найдены.<br/><br/><Links
                                    href="/app/review?elementId=0&reviewId=0"><b>Может добавить
                                    новый объект?</b></Links>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    }

                    {
                        (elementsData.length > 0 ?

                                <Grid container spacing={4}
                                      style={{
                                          margin: '20px 0px 20px -15px'
                                      }}
                                >

                                    {elementsDataFilter.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, i) => {

                                        return (

                                            <Grid item key={i} xs={12} sm={6} md={6}>
                                                <StyledCard>
                                                <span>
                                                    {
                                                        (row.photo.element !== undefined
                                                                ?
                                                                <img
                                                                    src={row.photo.element.preview.src[0]}
                                                                    alt={row.name}
                                                                />
                                                                :
                                                                <Skeleton animation="wave" variant="rect"
                                                                          height={150}
                                                                />
                                                        )
                                                    }
                                                </span>

                                                    <StyledCardContent>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            <Grid>
                                                                <StyledRatingBoxDiv>
                                                                    <Rating name="half-rating-read"
                                                                            style={{
                                                                                margin: '0 10px 0 0'
                                                                            }}
                                                                            value={parseFloat(row.rating)}
                                                                            precision={0.5} readOnly/>
                                                                </StyledRatingBoxDiv>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontSize: '0.8rem',
                                                                                margin: '3px 0 0 0'
                                                                            }}
                                                                >Рейтинг на
                                                                    основе {row.reviews_count_number} {row.reviews_count_text}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        {row.name.length === 0
                                                            ?
                                                            <div
                                                                style={{
                                                                    margin: '10px 0 10px 0'
                                                                }}
                                                            >
                                                                <Skeleton variant="text"/>
                                                                <Skeleton variant="text" width="80%"/>
                                                            </div>
                                                            :
                                                            <Typography gutterBottom variant="body2" component="h1"
                                                                        color="textPrimary"
                                                                        style={{
                                                                            color: '#000000',
                                                                            textShadow: 'none',
                                                                            backgroundImage: 'none',
                                                                            fontSize: '1.2rem',
                                                                            fontWeight: 400
                                                                        }}
                                                            >{row.name}
                                                            </Typography>
                                                        }
                                                    </StyledCardContent>
                                                    <CardActions>
                                                        <Grid container spacing={3}>
                                                            {row.representative && <Grid item xs={12}>
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontSize: '0.8rem',
                                                                                color: '#4caf50',
                                                                                textAlign: "center"
                                                                            }}
                                                                >
                                                                    <Tooltip title="Представитель объекта">
                                                                        <VerifiedUserIcon/>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Grid>}
                                                            <Grid item xs={12}>
                                                                <Divider/>
                                                                {
                                                                    (row.status === "4") && row.dateSave !== false &&
                                                                    <Alert variant="outlined" severity="info"
                                                                           style={{
                                                                               marginTop: '10px'
                                                                           }}
                                                                    >
                                                                        Черновик сохранен: {row.dateSave}
                                                                    </Alert>
                                                                }
                                                                {
                                                                    (row.status === "5" || row.status === "8") && row.dateSave !== false &&
                                                                    <Alert variant="outlined" severity="success"
                                                                           style={{
                                                                               marginTop: '10px'
                                                                           }}
                                                                    >
                                                                        Опубликован: {row.dateSave}
                                                                    </Alert>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <IconButton
                                                                    title="Редактировать"
                                                                    disabled={
                                                                        (row.status === "4" || row.edit ? false : true)
                                                                    }
                                                                    onClick={() => Promise.resolve(editElement(row.elementId, row.reviewId))}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    component="span"
                                                                >
                                                                    <EditIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={4}
                                                                  style={{
                                                                      textAlign: "center"
                                                                  }}>
                                                                <IconButton
                                                                    title="Удалить"
                                                                    disabled={
                                                                        (row.status === "4" ? false : true)
                                                                    }
                                                                    onClick={() => Promise.resolve(deleteElement(row.elementId, row.reviewId))}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    component="span"
                                                                >
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={4}
                                                                  style={{
                                                                      textAlign: 'right'
                                                                  }}
                                                            >
                                                                <IconButton
                                                                    title="Просмотр"
                                                                    disabled={
                                                                        row.status === "5" || row.status === "8" ? false : true
                                                                    }
                                                                    onClick={() => Promise.resolve(viewElement(row.url))}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    component="span"
                                                                >
                                                                    <VisibilityIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </CardActions>
                                                </StyledCard>
                                            </Grid>

                                        )
                                    })}

                                </Grid>
                                :
                                elementsInit && <StyledPaper
                                    style={{
                                        margin: '20px 0px 20px 0px'
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            Вы еще не добавляли объекты по товарам, компаниям или
                                            услугам. Воспользуйтесь поиском или добавьте новый.<br/><br/><Links
                                            href="/app/review?elementId=0&reviewId=0"><b>Добавить
                                            новый объект</b></Links>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                        )
                    }

                    {elementsDataFilter.length > 5 && <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>
                                <StyledPaginationBox>
                                    <Pagination
                                        count={Math.ceil(elementsDataFilter.length / rowsPerPage)}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChange={handleChangePage}
                                        showLastButton={(elementsDataFilter.length / rowsPerPage > rowsPerPage ? true : false)} // последняя страница true - показать
                                        showFirstButton={(elementsDataFilter.length / rowsPerPage > rowsPerPage ? true : false)} // первая страница true - показать
                                        variant="outlined" shape="rounded" color="primary"
                                        size="small"
                                    />
                                </StyledPaginationBox>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }


                </Grid>
            </Grid>
        </>
    );

}

Elements.propTypes = {
    window: PropTypes.func,
};

export default Elements