import React from "react"
import {navigate} from "gatsby"
import {isLoggedIn, setUser} from "../services/auth"
import Avatar from '@mui/material/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {styled} from "@mui/material/styles";
import Container from '@material-ui/core/Container';
import {deepOrange} from '@material-ui/core/colors';
import {Formik} from 'formik';
import Alert from '@material-ui/lab/Alert';
import {Helmet} from "react-helmet";

const StyledContainer = styled(Container)(({theme}) => ({
    marginTop: "20px",
    margin: 'auto',
}));
const StyledAvatar = styled(Avatar)(({theme}) => ({
    backgroundColor: deepOrange[500],
    marginTop: "20px",
}));

class Confirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {message: '', captcha: '', sid: ''};
    }

    componentDidMount() {
        this.getCaptcha();
    }

    getCaptcha = () => {
        fetch("https://api.ocenivay.com/api/captcha-sid.php", {
            method: "POST",
            // body: JSON.stringify({values})
        })
            .then(response => response.json())
            .then(data => {
                this.setState({sid: data})
                this.setState({captcha: '<img src="https://api.ocenivay.com/bitrix/tools/captcha.php?captcha_sid=' + data + '" width="180" height="40" alt="CAPTCHA">'})
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            })
    }


    render() {

        if (isLoggedIn()) {
            navigate(`/app/profile`)
        }

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id') === null ? "" : queryParams.get('id');
        const code = queryParams.get('code') === null ? "" : queryParams.get('code');
        const initialValues = {
            id: id, code: code, captcha: "", sid: ""
        };

        return (
            <>
                <Helmet defer={false}>
                    <html lang="ru-RU"/>
                    <title>Подтверждение регистрации</title>
                    <meta name="description" content="Подтверждение регистрации"/>
                    <meta name="robots" content="noindex"/>
                    <meta http-equiv="cache-control" content="no-cache"/>
                    <meta http-equiv="expires" content="0"/>
                    <meta http-equiv="pragma" content="no-cache"/>
                </Helmet>
                <StyledContainer component="main" maxWidth="xs">
                        <Formik
                            initialValues={initialValues}
                            validate={values => {
                                const errors = {};
                                if (!values.id) {
                                    errors.id = "Поле обязательное к заполнению!";
                                }
                                if (!values.code) {
                                    errors.code = "Поле обязательное к заполнению!";
                                }
                                if (
                                    !/^[A-Z0-9]{5,}$/i.test(values.captcha)
                                ) {
                                    errors.captcha = "5 латинских символов или цифр из изображения";
                                }
                                return errors;
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                values.sid = this.state.sid;
                                fetch("https://api.ocenivay.com/api/user-confirm.php", {
                                    method: "POST",
                                    // headers: {
                                    //     'Content-Type': 'application/json',
                                    //     Accept: 'application/json',
                                    // },
                                    body: JSON.stringify({values})
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        if (data.message) {
                                            this.getCaptcha()
                                            this.setState({message: data.message})
                                        } else {
                                            setUser(data.user)
                                            document.location.href = "/app/profile"
                                        }
                                    })
                                setSubmitting(false);
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  /* and other goodies */
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid>
                                            <StyledAvatar>
                                                <LockOutlinedIcon/>
                                            </StyledAvatar>
                                        </Grid>
                                        <Grid>
                                            <Typography component="h1" variant="h5"
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                            >
                                                Подтверждение регистрации
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {(this.state.message ? <Alert severity="error" style={{
                                        margin: "30px 0px 20px 0px"
                                    }}>{this.state.message}</Alert> : "")}
                                    {(this.state.confirm ? <Alert severity="info" style={{
                                                margin: "30px 0px 20px 0px"
                                            }}>{this.state.confirm}</Alert> :
                                            <>
                                                <TextField
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.id}
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="id"
                                                    label="ID"
                                                    name="id"
                                                />
                                                {(errors.id && touched.id ? <Alert
                                                    severity="warning">{errors.id && touched.id && errors.id}</Alert> : "")}
                                                <TextField
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.code}
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="code"
                                                    label="Код подтверждения"
                                                    name="code"
                                                />
                                                {(errors.code && touched.code ? <Alert
                                                    severity="warning">{errors.code && touched.code && errors.code}</Alert> : "")}
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    style={{
                                                        margin: '15px 0 0 0'
                                                    }}
                                                >
                                                    <Grid>
                                                        <div dangerouslySetInnerHTML={{__html: this.state.captcha}}></div>
                                                    </Grid>
                                                    <Grid>
                                                        <Link href="#" onClick={this.getCaptcha}
                                                              style={{
                                                                  margin: '0 0 0 25px'
                                                              }}
                                                        >
                                                            Обновить
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                                <TextField
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.captcha}
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="captcha"
                                                    label="Латинские символы"
                                                    name="captcha"
                                                />
                                                {(errors.captcha && touched.captcha ? <Alert
                                                    severity="warning">{errors.captcha && touched.captcha && errors.captcha}</Alert> : "")}
                                                <Button
                                                    style={{
                                                        margin: '25px 0 0 0'
                                                    }}
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Подтвердить
                                                </Button>
                                                <Grid container
                                                      style={{
                                                          margin: '15px 0 0 0'
                                                      }}
                                                >
                                                    <Grid item xs>
                                                        <Link href="/app/forgot" variant="body2">
                                                            Забыли пароль?
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link href="/app/login" variant="body2">
                                                            Войти
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </>
                                    )}

                                </form>
                            )}
                        </Formik>
                </StyledContainer>
            </>
        )
    }
}

export default Confirm