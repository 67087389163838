import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {Helmet} from "react-helmet";
import {titles, drawerWidth, drawer} from "../services/auth";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Links from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useTable} from 'react-table'
import axios from "axios";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledPaperLeftMenu = styled(Paper)(({theme}) => ({
    padding: theme.spacing(0),
    margin: 'auto',
}));
const StyledDrawerNav = styled('nav')(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    marginRight: "20px",
}));
const StyledIconButton = styled(IconButton)(({theme}) => ({
    [theme.breakpoints.up(960)]: {
        display: 'none',
    },
}));
const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
}));
const StyledTableCell = styled(TableCell)(({theme}) => ({
    fontSize: "0.7rem",
    fontWeight: 400
}));

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return <input type="checkbox" ref={resolvedRef} {...rest} />
    }
)

// <pre>{JSON.stringify(column, null, 2)}</pre>

function Table({columns, data, getRepresentative}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        getToggleHideAllColumnsProps,
    } = useTable({
        columns,
        data,
        getRepresentative
    })

    const [initRepresentative, setInitRepresentative] = useState(false);
    const [representativeId, setRepresentativeId] = useState(null);
    const [elementData, setElementData] = useState({
        statusId: null,
        comment: false
    });
    const [statusId, setStatusId] = useState(null);
    const [statusList, setStatusList] = useState([]);

    function openFormRepresentative(representativeId) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(elementData)) {
            formData.append(key, value)
        }
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        formData.append(
            "representativeId", representativeId,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/representative-get.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data.error !== undefined) {
                    setSnackbarMessage(res.data.severity, res.data.message)
                } else {
                    setStatusId(res.data.statusId);
                    setStatusList(res.data.statusList);
                    setInitRepresentative(true)
                    setRepresentativeId(representativeId)
                    setElementData({
                        statusId: res.data.statusId,
                        comment: res.data.comment,
                    });
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    function closeFormRepresentative() {
        setInitRepresentative(false)
    }

    function handleRepresentativeChange(event) {

        const target = event !== undefined ? event.target : undefined;

        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            setElementData({
                ...elementData, [name]: value
            });
        }
    }


    function sendCode(representativeId) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(elementData)) {
            formData.append(key, value)
        }
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        formData.append(
            "representativeId", representativeId,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/representative-send.php",
            responseType: 'json',
        })
            .then(res => {
                closeFormRepresentative()
                getRepresentative()
                setSnackbarMessage(res.data.severity, res.data.message)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    // Render the UI for your table
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Dialog open={initRepresentative}
                    onClose={closeFormRepresentative}
                    fullWidth={true}
                    maxWidth={'sm'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="form-dialog-title"><VerifiedUserIcon style={{
                    margin: "0px 10px -5px 0px",
                    color: "green"
                }}/>Отправка кода доступа представителю</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{
                        fontSize: "0.8rem"
                    }}>
                                                    <span
                                                        style={{
                                                            color: "#333333",
                                                        }}
                                                    >
                                                        Необходимо выбрать статус и при желании заполнить комментарий.
                                                    </span>
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel
                                    htmlFor="status"
                                    style={{
                                        marginLeft: "15px",
                                        marginTop: "-5px",
                                    }}>Статус</InputLabel>
                                <Select
                                    variant="outlined"
                                    id="statusId"
                                    name="statusId"
                                    label="Статус"
                                    defaultValue={statusId ? statusId : null}
                                    onChange={handleRepresentativeChange}
                                    inputProps={{
                                        name: "statusId",
                                        id: "status"
                                    }}
                                >
                                    {statusList.map(item => {
                                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                id="comment"
                                name="comment"
                                label="Комментарий"
                                type="text"
                                defaultValue={setElementData.comment ? setElementData.comment : null}
                                fullWidth
                                multiline
                                onChange={handleRepresentativeChange}
                                minRows={4}
                                maxRows={8}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        margin: "20px 16px 10px 16px",
                    }}
                ><Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Grid>
                        <Button onClick={closeFormRepresentative}
                                variant="contained" size="small"
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    margin: "0 20px 0 0",
                                }}
                        >
                            Отменить
                        </Button>
                    </Grid>
                    <Grid>
                        <Button onClick={() => Promise.resolve(sendCode(representativeId))}
                                variant="contained" size="small" color="primary"
                                style={{
                                    textShadow: 'none',
                                    backgroundImage: 'none',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                }}
                        >
                            >>
                        </Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>

            <div style={{
                textAlign: "center"
            }}>
                <span>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Все&nbsp;
                </span>
                {allColumns.map(column => (
                    <span key={column.id}>
                        <label>
                            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                            {column.Header}&nbsp;{}
                        </label>
                    </span>
                ))}
                <br/>
            </div>
            <TableContainer>
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <StyledTableCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            cell.column.id === "element" ?
                                                <StyledTableCell {...cell.getCellProps()}>
                                                    <Links href={cell.value.url}
                                                           target="_blank">{cell.value.name}</Links>
                                                </StyledTableCell>
                                                :
                                                cell.column.id === "createdBy" ?
                                                    <StyledTableCell {...cell.getCellProps()}>
                                                        <b>{cell.value.name}</b><br/>{cell.value.email}
                                                    </StyledTableCell>
                                                    :
                                                    cell.column.id === "site" ?
                                                        <StyledTableCell {...cell.getCellProps()}>
                                                            <Links href={cell.value}
                                                                   target="_blank">{cell.value}</Links>
                                                        </StyledTableCell>
                                                        :
                                                        cell.column.id === "code" ?
                                                            <StyledTableCell {...cell.getCellProps()}>
                                                                <Button variant="contained" size="small"
                                                                        onClick={() => Promise.resolve(openFormRepresentative(row.cells[i].row.original.id))}
                                                                        style={{
                                                                            textShadow: 'none',
                                                                            backgroundImage: 'none',
                                                                            fontSize: '0.6rem',
                                                                            fontWeight: "bold",
                                                                            textTransform: 'none',
                                                                            margin: '5px'
                                                                        }}
                                                                        title="Отправить"
                                                                >Отправить</Button>
                                                            </StyledTableCell>
                                                            :
                                                            <StyledTableCell {...cell.getCellProps()}>
                                                                {cell.render('Cell')}
                                                            </StyledTableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </MaUTable>
            </TableContainer>
        </>
    )
}

const Representatives = (props) => {

    const {window} = props;
    const pathName = props.path
    const [valueTitle, setValueTitle] = useState()

    function setTitle() {
        titles.map((title, t) => (
            typeof pathName !== 'undefined' && pathName === title.link && valueTitle !== title.name ? setValueTitle(title.name) : ""
        ))
    }

    setTitle()

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [columns, setColumns] = useState([]);
    const [representative, setRepresentative] = useState([]);

    function getRepresentative() {
        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/representatives-get.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data.error !== undefined) {
                    setColumns([]);
                    setRepresentative([]);
                } else {
                    setColumns(res.data.columns);
                    setRepresentative(res.data.representative);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    useEffect(() => {
        getRepresentative()
    }, []);

    return (

        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{valueTitle}</title>
                <meta name="description" content={valueTitle}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Grid container>

                <Grid item xs={false} md={3}>
                    <StyledDrawerNav aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <StyledDrawer
                                container={container}
                                variant="temporary"
                                anchor="left"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </StyledDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <StyledPaperLeftMenu>{drawer}</StyledPaperLeftMenu>
                        </Hidden>
                    </StyledDrawerNav>
                </Grid>
                <Grid item xs={12} md={9}>

                    <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom component="h1" variant="h5"
                                            color="textPrimary">
                                    <StyledIconButton
                                        color="inherit"
                                        aria-label="show more"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            textTransform: 'none',
                                            margin: '-7px 0px 0px -10px'
                                        }}
                                    >
                                        <MenuIcon/>
                                    </StyledIconButton>

                                    {valueTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>Запросы на представителя с сайта.</Typography>
                            </Grid>
                        </Grid>
                    </StyledPaper>

                    <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>

                                <Table columns={columns} data={representative} getRepresentative={getRepresentative}/>

                            </Grid>
                        </Grid>
                    </StyledPaper>

                </Grid>
            </Grid>
        </>
    );

}

Representatives.propTypes = {
    window: PropTypes.func,
};

export default Representatives