import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Helmet} from "react-helmet";
import {titles, drawerWidth, drawer} from "../services/auth";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Links from "@material-ui/core/Link";
import Rating from "@material-ui/lab/Rating";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from '@material-ui/icons/Clear';
import Divider from "@material-ui/core/Divider";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CardActions from "@material-ui/core/CardActions";
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AddCommentIcon from "@material-ui/icons/AddComment";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledPaperLeftMenu = styled(Paper)(({theme}) => ({
    padding: theme.spacing(0),
    margin: 'auto',
}));
const StyledDrawerNav = styled('nav')(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    marginRight: "20px",
}));
const StyledIconButton = styled(IconButton)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));
const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
}));
const StyledCardContent = styled(CardContent)(({theme}) => ({
    flexGrow: 1,
}));
const StyledRatingBoxDiv = styled('div')(({theme}) => ({
    padding: 0,
    margin: 0,
}));
const StyledCard = styled(Card)(({theme}) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
const StyledPaginationBox = styled(Box)(({theme}) => ({
    marginTop: theme.spacing(2),
    justifyContent: "center",
    display: 'flex'
}));

let q = "";
if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search);
    q = queryParams.get('q') === null ? "" : queryParams.get('q');
}

const Reviews = (props) => {

    const {window} = props;
    const pathName = props.path
    const [valueTitle, setValueTitle] = useState()

    function setTitle() {
        titles.map((title, t) => (
            typeof pathName !== 'undefined' && pathName === title.link && valueTitle !== title.name ? setValueTitle(title.name) : ""
        ))
    }

    setTitle()

    const [page, setPage] = useState(1);
    const rowsPerPage = 6;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [reviewsData, setReviewsData] = useState([]);
    const [reviewsInit, setReviewsInit] = useState(false);
    const [reviewDelete, setReviewDelete] = useState(null);
    const [reviewsDataFilter, setReviewsDataFilter] = useState([]);
    const [reviewsLoading, setReviewsLoading] = useState(true);

    function search(q) {
        if (q.target !== undefined) {
            q = q.target.value;
        }
        if (q !== undefined) {
            setSearchVal(q);
            let lowerCase = q.toLowerCase();
            let resultSearch = [];
            reviewsData.filter(row => {
                setPage(1);
                if (row.name.toLowerCase().includes(lowerCase.toLowerCase())) {
                    resultSearch.push(row);
                }
                return true;
            });
            if (lowerCase === "") {
                setReviewsDataFilter(reviewsData);
            } else {
                setReviewsDataFilter(resultSearch);
            }
        } else {
            setPage(1);
            setReviewsDataFilter(reviewsData);
        }
    }

    const [searchVal, setSearchVal] = useState("");

    function clearSearch() {
        setSearchVal("");
        search("");
    }

    useEffect(() => {
        let values;
        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        values = Object.assign(formData);
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/reviews-get.php",
            responseType: 'json',
        })
            .then(res => {
                setReviewsLoading(false)
                setReviewsData(res.data)
                setReviewsDataFilter(res.data)
                setReviewsInit(true)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewDelete]);

    useEffect(() => {
        setSearchVal(q);
        search(q);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewsData]);

    function editReview(elementId, reviewId) {
        document.location.href = "/app/review?elementId=" + elementId + "&reviewId=" + reviewId + "";
    }

    function deleteReview(elementId, reviewId) {
        let values;
        let formData = new FormData();
        formData.append(
            "jwt", JSON.parse(localStorage.getItem("gatsbyUser")).jwt,
        );
        formData.append(
            "email", JSON.parse(localStorage.getItem("gatsbyUser")).email,
        );
        formData.append(
            "reviewId", reviewId,
        );
        values = Object.assign(formData);
        axios({
            method: "POST",
            data: values,
            url: "https://api.ocenivay.com/api/delete.php",
            responseType: 'json',
        })
            .then(res => {
                setReviewDelete(reviewId);
                setSnackbarMessage(res.data.severity, res.data.message);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    function viewReview(url) {
        var a = document.createElement('a');
        a.target = "_blank";
        a.href = url;
        a.click();
    }

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    return (

        <>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{valueTitle}</title>
                <meta name="description" content={valueTitle}/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <Grid container>

                <Grid item xs={false} md={3}>
                    <StyledDrawerNav aria-label="mailbox folders">
                        <Hidden smUp implementation="css">
                            <StyledDrawer
                                container={container}
                                variant="temporary"
                                anchor="left"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </StyledDrawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <StyledPaperLeftMenu>{drawer}</StyledPaperLeftMenu>
                        </Hidden>
                    </StyledDrawerNav>
                </Grid>
                <Grid item xs={12} md={9}>

                    <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom component="h1" variant="h5"
                                            color="textPrimary">
                                    <StyledIconButton
                                        color="inherit"
                                        aria-label="show more"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        style={{
                                            textShadow: 'none',
                                            backgroundImage: 'none',
                                            textTransform: 'none',
                                            margin: '-7px 0px 0px -10px'
                                        }}
                                    >
                                        <MenuIcon/>
                                    </StyledIconButton>

                                    {valueTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>Список всех ваших отзывов на товары, компании и
                                    услуги. Для добавления нового отзыва воспользуйтесь поиском выше.</Typography>
                            </Grid>
                        </Grid>
                    </StyledPaper>

                    {reviewsLoading &&
                    <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>
                                <>
                                    <Typography variant="body2" gutterBottom><b>Загрузка отзывов...</b></Typography>
                                    <CircularProgress/>
                                </>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }


                    {reviewsData.length > 6 && <StyledPaper
                        style={{
                            margin: '20px 0px 20px 0px'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>


                                <FormControl
                                    fullWidth
                                >
                                    <InputLabel
                                        style={{
                                            fontSize: '0.9rem'
                                        }}
                                        htmlFor="search">Поиск среди моих отзывов</InputLabel>
                                    <Input
                                        style={{
                                            fontSize: '0.9rem'
                                        }}
                                        onChange={search}
                                        id="search"
                                        value={searchVal}
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                onClick={clearSearch}>
                                                <IconButton
                                                    aria-label="name"
                                                    size="small"><ClearIcon
                                                    fontSize="inherit"
                                                /></IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <StyledPaginationBox>
                                    <Pagination
                                        count={Math.ceil(reviewsDataFilter.length / rowsPerPage)}
                                        rowsPerPage={parseInt(rowsPerPage)}
                                        page={Math.ceil(page)}
                                        onChange={handleChangePage}
                                        showLastButton={(Math.ceil(reviewsDataFilter.length / rowsPerPage) > rowsPerPage ? true : false)} // последняя страница true - показать
                                        showFirstButton={(Math.ceil(reviewsDataFilter.length / rowsPerPage) > rowsPerPage ? true : false)} // первая страница true - показать
                                        variant="outlined" shape="rounded" color="primary"
                                        size="small"
                                    />
                                </StyledPaginationBox>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                    }

                    {
                        reviewsData.length > 0 && reviewsInit && reviewsDataFilter.length === 0 &&
                        <StyledPaper
                            style={{
                                margin: '20px 0px 20px 0px'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    По запросу ваши отзывы не найдены.<br/><br/><Links
                                    href="/app/review?elementId=0&reviewId=0"><b>Может добавить
                                    новый отзыв?</b></Links>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    }


                    {
                        (reviewsData.length > 0 ?

                                <Grid container spacing={4}
                                      style={{
                                          margin: '20px 0px 20px -15px'
                                      }}
                                >


                                    {reviewsDataFilter.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row, i) => {

                                        return (

                                            <Grid item key={i} xs={12} sm={6} md={6}>
                                                <StyledCard>
                                                <span>
                                                    {
                                                        (row.photo.element !== undefined
                                                                ?
                                                                <img
                                                                    src={row.photo.element.preview.src[0]}
                                                                    alt={row.name}
                                                                />
                                                                :
                                                                <Skeleton animation="wave" variant="rect"
                                                                          height={150}
                                                                />
                                                        )
                                                    }
                                                </span>

                                                    <StyledCardContent>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            <Grid>
                                                                <StyledRatingBoxDiv>
                                                                    <Rating name="half-rating-read"
                                                                            style={{
                                                                                margin: '0 10px 0 0'
                                                                            }}
                                                                            value={parseFloat(row.rating)}
                                                                            precision={0.5} readOnly/>
                                                                </StyledRatingBoxDiv>
                                                            </Grid>
                                                            <Grid>
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontSize: '0.8rem',
                                                                                margin: '3px 0 0 0'
                                                                            }}
                                                                >Рейтинг на
                                                                    основе {row.reviews_count_number} {row.reviews_count_text}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        {row.name.length === 0
                                                            ?
                                                            <div
                                                                style={{
                                                                    margin: '10px 0 10px 0'
                                                                }}
                                                            >
                                                                <Skeleton variant="text"/>
                                                                <Skeleton variant="text" width="80%"/>
                                                            </div>
                                                            :
                                                            <Typography gutterBottom variant="body2" component="h1"
                                                                        color="textPrimary"
                                                                        style={{
                                                                            color: '#000000',
                                                                            textShadow: 'none',
                                                                            backgroundImage: 'none',
                                                                            fontSize: '1.2rem',
                                                                            fontWeight: 400
                                                                        }}
                                                            >{row.name}
                                                            </Typography>
                                                        }

                                                        {row.text.length === 0
                                                            ?
                                                            <div
                                                                style={{
                                                                    margin: '10px 0 10px 0'
                                                                }}
                                                            >
                                                                <Skeleton variant="text"/>
                                                                <Skeleton variant="text" width="80%"/>
                                                                <Skeleton variant="text"/>
                                                                <Skeleton variant="text" width="90%"/>
                                                                <Skeleton variant="text" width="30%"/>
                                                            </div>
                                                            :
                                                            <Typography variant="body2" color="textPrimary"
                                                                        component="p"
                                                                        gutterBottom>
                                                                {row.text}
                                                            </Typography>
                                                        }


                                                    </StyledCardContent>
                                                    <CardActions>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontSize: '0.8rem',
                                                                                color: '#4caf50',
                                                                                textAlign: "center"
                                                                            }}
                                                                >
                                                                    <Tooltip title="Отзыв полезен">
                                                                        <ThumbUpAltIcon/>
                                                                    </Tooltip>
                                                                    {parseInt(row.plus)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>{row.representative &&
                                                            <Typography variant="body2" component="p"
                                                                        color="textPrimary"
                                                                        style={{
                                                                            fontSize: '0.8rem',
                                                                            color: '#4caf50',
                                                                            textAlign: "center",
                                                                        }}
                                                            >
                                                                <Tooltip title="Представитель объекта">
                                                                    <VerifiedUserIcon/>
                                                                </Tooltip>
                                                            </Typography>}
                                                            </Grid>
                                                            <Grid item xs={3}>{row.representative &&
                                                            <Typography variant="body2" component="p"
                                                                        color="textPrimary"
                                                                        style={{
                                                                            fontSize: '0.8rem',
                                                                            textAlign: "center",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => Promise.resolve(viewReview(row.url))}
                                                            >
                                                                {row.comment ?
                                                                    <><Tooltip title="Есть ответ">
                                                                        <EditIcon color="primary"/>
                                                                    </Tooltip></>
                                                                    :
                                                                    <><Tooltip title="Нет ответа">
                                                                        <AddCommentIcon color="primary"/>
                                                                    </Tooltip></>
                                                                }
                                                            </Typography>}
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" component="p"
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontSize: '0.8rem',
                                                                                color: '#f44336',
                                                                                textAlign: "center"
                                                                            }}
                                                                >
                                                                    <Tooltip title="Отзыв бесполезен">
                                                                        <ThumbDownAltIcon/>
                                                                    </Tooltip>
                                                                    {parseInt(row.minus)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider/>
                                                                {
                                                                    (row.status === "6" || row.status === "45") && row.dateSave !== false &&
                                                                    <Alert variant="outlined" severity="info"
                                                                           style={{
                                                                               marginTop: '10px'
                                                                           }}
                                                                    >
                                                                        Черновик от: {row.dateSave}
                                                                    </Alert>
                                                                }
                                                                {
                                                                    (row.status === "7" || row.status === "9" || row.status === "43") && row.dateSave !== false &&
                                                                    <Alert variant="outlined" severity="success"
                                                                           style={{
                                                                               marginTop: '10px'
                                                                           }}
                                                                    >
                                                                        Опубликован: {row.dateSave}
                                                                    </Alert>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <IconButton
                                                                    title="Редактировать"
                                                                    disabled={
                                                                        row.status === "6" || row.status === "45" || row.edit ? false : true
                                                                    }
                                                                    onClick={() => Promise.resolve(editReview(row.elementId, row.reviewId))}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    component="span"
                                                                >
                                                                    <EditIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={4}
                                                                  style={{
                                                                      textAlign: "center"
                                                                  }}>
                                                                <IconButton
                                                                    title="Удалить"
                                                                    disabled={
                                                                        row.status === "6" || row.status === "46" ? false : true
                                                                    }
                                                                    onClick={() => Promise.resolve(deleteReview(row.elementId, row.reviewId))}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    component="span"
                                                                >
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={4}
                                                                  style={{
                                                                      textAlign: 'right'
                                                                  }}
                                                            >
                                                                <IconButton
                                                                    title="Просмотр"
                                                                    disabled={
                                                                        row.status === "7" || row.status === "9" || row.status === "43" ? false : true
                                                                    }
                                                                    onClick={() => Promise.resolve(viewReview(row.url))}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    component="span"
                                                                >
                                                                    <VisibilityIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </CardActions>
                                                </StyledCard>
                                            </Grid>

                                        )
                                    })}

                                </Grid>
                                :
                                reviewsInit && <StyledPaper
                                    style={{
                                        margin: '20px 0px 20px 0px'
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            Вы еще не добавляли отзывы на товары, компании или
                                            услуги. Воспользуйтесь поиском или добавьте новый.<br/><br/><Links
                                            href="/app/review?elementId=0&reviewId=0"><b>Добавить
                                            новый отзыв</b></Links>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                        )
                    }

                    {reviewsDataFilter.length > 5 && <StyledPaper>
                        <Grid container>
                            <Grid item xs={12}
                                  style={{
                                      textAlign: 'center'
                                  }}>
                                <StyledPaginationBox>
                                    <Pagination
                                        count={Math.ceil(reviewsDataFilter.length / rowsPerPage)}
                                        rowsPerPage={parseInt(rowsPerPage)}
                                        page={Math.ceil(page)}
                                        onChange={handleChangePage}
                                        showLastButton={(Math.ceil(reviewsDataFilter.length / rowsPerPage) > rowsPerPage ? true : false)} // последняя страница true - показать
                                        showFirstButton={(Math.ceil(reviewsDataFilter.length / rowsPerPage) > rowsPerPage ? true : false)} // первая страница true - показать
                                        variant="outlined" shape="rounded" color="primary"
                                        size="small"
                                    />
                                </StyledPaginationBox>
                            </Grid>
                        </Grid>
                    </StyledPaper>}


                </Grid>
            </Grid>
        </>
    );

}

Reviews.propTypes = {
    window: PropTypes.func,
};

export default Reviews