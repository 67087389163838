import React from "react"
import {Router} from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Reviews from "../components/reviews"
import Elements from "../components/elements"
import Notifications from "../components/notifications"
import Representatives from "../components/representatives"
import Login from "../components/login"
import Reg from "../components/reg"
import Confirm from "../components/confirm"
import Forgot from "../components/forgot"
import PasswordReset from "../components/passwordReset"
import Container from "@material-ui/core/Container";
import {styled} from '@mui/material/styles';
import Review from "../components/review";
import {isBrowser} from "../services/auth";
import Test from "../components/test"

const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));

const NotFound = () => (
    isBrowser() ? window.location.replace("https://ocenivay.com/notFound") : ""
)

const App = () => {
    return (
        <>
            {
                (typeof window !== 'undefined' && window.location.href.indexOf("/app/test") > -1 ?
                        <Router>
                            <Test path="/app/test"/>
                        </Router>
                        :
                        <Layout>
                            <main>
                                <StyledContainer maxWidth="md">
                                    <Router>
                                        <PrivateRoute path="/app/profile" component={Profile}/>
                                        <PrivateRoute path="/app/reviews" component={Reviews}/>
                                        <PrivateRoute path="/app/elements" component={Elements}/>
                                        <PrivateRoute path="/app/review" component={Review}/>
                                        <PrivateRoute path="/app/notifications" component={Notifications}/>
                                        <PrivateRoute path="/app/representatives" component={Representatives}/>
                                        <Login path="/app/login"/>
                                        <Reg path="/app/reg" component={Reg}/>
                                        <Confirm path="/app/confirm"/>
                                        <Forgot path="/app/forgot"/>
                                        <PasswordReset path="/app/password-reset"/>
                                        <NotFound default />
                                    </Router>
                                </StyledContainer>
                            </main>
                        </Layout>
                )
            }
        </>
    )
}
export default App